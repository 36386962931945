export const addIndianCommas = value => {
    if (value === undefined || value === null || value === '') return value;
    const cleanedText = value.toString().replace(/[^0-9]/g, '');
    return cleanedText.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
};

export const removeIndianCommas = value => {
    if (value === undefined || value === null || value === '') return value;
    if (typeof value === 'number') return value;
    return Number(value.replace(/[^0-9]/g, ''));
};