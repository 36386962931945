import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Dashboard, ServiceRequests } from "..";
import ForgotPassword from "./LoginManagement/ForgotPassword";
import ChangePassword from "./LoginManagement/ChangePassword";
import { useStateContext } from "../../contexts/ContextProvider";
import Transactions from "../Transactions/Transactions";
import Profile from "../Profile";
import Funds from "../Funds";
import Classes from "../Classes";
import Utilities from "../Utilities";
import Settings from "../Settings";
import ReportGeneration from "../Reports/ReportGeneration";
import QuerySelection from "../QueryModule/QuerySelection";
import DocumentUpload from "../DocumentUpload";
import AdvanceQuerySelection from "../QueryModule/AdavanceQuery/AdvanceQuerySelection";
import QueryProfileDetails from "../QueryModule/QueryDetails/QueryProfileDetails";

const Application = () => {
  const { accountNumber, loggedIn, clientId, fundTableData, classTableData } =
    useStateContext();

  return (
    <div style={{ overflowX: "hidden", height: "fit-content" }}>
      <Routes>
        {/* Dashboard or Home*/}
        <Route
          path="/Login"
          element={
            loggedIn ? (
              <Navigate to={`/Dashboard`} />
            ) : (
              <Navigate to={`/Login`} />
            )
          }
        />

        <Route path="/ForgotPassword" element={<ForgotPassword />} />

        <Route path="/ChangePassword" element={<ChangePassword />} />

        <Route
          path="/Dashboard"
          // element={<Home />}
          element={loggedIn ? <Dashboard /> : <Navigate to={`/Login`} />}
        />
        <Route
          path="/Transactions"
          element={
            loggedIn ? (
              <Transactions data={clientId} />
            ) : (
              <Navigate to={`/Login`} />
            )
          }
        />

        <Route
          path="/Reports"
          element={
            loggedIn ? (
              <ReportGeneration clientId={1} />
            ) : (
              <Navigate to={`/Login`} />
            )
          }
        />
        <Route
          path="/Utilities"
          element={loggedIn ? <Utilities /> : <Navigate to={`/Login`} />}
        />
        <Route
          path="/ServiceRequests"
          element={loggedIn ? <ServiceRequests /> : <Navigate to={`/Login`} />}
        />
        <Route
          path="/Profile"
          element={
            loggedIn ? <Profile data={clientId} /> : <Navigate to={`/Login`} />
          }
        />
        <Route
          path="/Funds"
          element={
            loggedIn ? (
              <Funds data={fundTableData} />
            ) : (
              <Navigate to={`/Login`} />
            )
          }
        />
        <Route
          path="/Classes"
          element={
            loggedIn ? (
              <Classes data={classTableData} />
            ) : (
              <Navigate to={`/Login`} />
            )
          }
        />
        <Route
          path="/Settings"
          element={loggedIn ? <Settings /> : <Navigate to={`/Login`} />}
        />

        <Route
          path={`/Query/ProfileData/Profile`}
          element={
            loggedIn ? (
              <QueryProfileDetails data={accountNumber} />
            ) : (
              <Navigate replace to={`/Login`} />
            )
          }
        />

        <Route
          path={`/QuerySelection`}
          element={
            loggedIn ? <QuerySelection /> : <Navigate replace to={`/Login`} />
          }
        />
        <Route
          path={`/AdvancedQuerySelection`}
          element={
            loggedIn ? (
              <AdvanceQuerySelection />
            ) : (
              <Navigate replace to={`/Login`} />
            )
          }
        />

        <Route
          path={`/DocumentUpload`}
          element={
            loggedIn ? <DocumentUpload /> : <Navigate replace to={`/Login`} />
          }
        />
      </Routes>
    </div>
  );
};

export default Application;
