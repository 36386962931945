import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { useStateContext } from "../contexts/ContextProvider";
import { Link } from "react-router-dom";
import moment from "moment";

const Profile = () => {
  const [tableData, setTableData] = useState([]);
  const [fundTable, setFundTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const { apiBaseUrl, setFundTableData, client_id } = useStateContext();
  const [client, setClient] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const onSelectFund = (record) => {
    sessionStorage.setItem("fundTableData", JSON.stringify(record));
    setFundTableData(record);
  };

  const columns = [
    {
      title: "S. No",
      dataIndex: "sno",
      align: "center",
      width: 50,
    },
    {
      title: "Fund Name",
      dataIndex: "fund_Description",
      align: "center",
      width: 180,
    },
    {
      title: "Fund Code",
      dataIndex: "code",
      align: "center",
      width: 90,
    },

    {
      title: "Status",
      dataIndex: "fund_Status",
      align: "center",
      width: 80,
    },
    {
      title: "View Details",
      dataIndex: "action",
      align: "center",
      width: 130,
      render: (_, record) => {
        return (
          <div style={{ alignSelf: "center", marginLeft: "32%" }}>
            <Button
              onClick={() => onSelectFund(record)}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "80px",
                height: "33px",
                backgroundColor: "#A3020C",
                color: "white",
                borderRadius: 5,
              }}
            >
              <Link to={{ pathname: `/Funds` }}>View</Link>
            </Button>
          </div>
        );
      },
    },
  ];

  const showFunds = () => {
    setLoading(true);

    fetch(`${apiBaseUrl}/VClient/GetClientbyClientid/${client_id}`)
      .then((response) => response.json())
      .then((json) => {
        setClient(json.responseData);
        console.log(json.responseData);
        fetch(
          `${apiBaseUrl}/Vlookup/GetAddressData?address_id=${json.responseData.address_id}`
        )
          .then((response) => response.json())
          .then((json) => {
            setAddressData(json.responseData[0]);
            console.log("Address Data", json.responseData);
          });
      });

    fetch(`${apiBaseUrl}/VFund/GetFundbyCilentid/${client_id}`)
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: json.responseData.length,
          },
        });
        console.log("Funds", json.responseData);
        setTableData(json.responseData);
      })
      .catch((error) => alert("something went error"));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  useEffect(() => {
    showFunds();
  }, []);

  useEffect(() => {
    tableData.forEach((ele, index) => {
      ele.sno = index + 1;
      ele.maturity_Date = moment(ele.maturity_Date)
        .format("DD/MM/YYYY")
        .toString();
    });
    setFundTable(tableData);
  }, [tableData]);

  return (
    <>
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          fontSize: "30px",
          color: "#A3020C",
        }}
      >
        {client.client_name}
      </h1>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "95%",
          margin: "0 auto",
          backgroundColor: "#f5f5f5",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            padding: "20px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", width: "50%" }}
            >
              <h2 style={{ fontSize: 18 }}>Profile Details</h2>
              <p> Name : {client.client_name}</p>
              <p> Code : {client.client_code}</p>
              <h3 style={{ marginTop: "2%", fontSize: 18 }}>Contact Details</h3>
              <p>Primary Telephone : {client.primary_telephone_number}</p>
              <p>
                Secondary Telephone :
                {client.secondary_telephone_number === "+"
                  ? " Not Available"
                  : client.secondary_telephone_number}
              </p>
              <p>Fax Number : {client.fax_number}</p>
              <p>Primary Email : {client.primary_email_id}</p>
              <p>
                Secondary Email :{" "}
                {client.secondary_email_id
                  ? client.secondary_email_id
                  : "Not Available"}
              </p>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "50%" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h3 style={{ fontSize: 18 }}>Registration Details</h3>
                <p>
                  Government Registration ID:{" "}
                  {client.government_registration_id === ""
                    ? " Not Available"
                    : client.government_registration_id}
                </p>
                <p>
                  Regulator Registration ID:{" "}
                  {client.regulator_registration_id === ""
                    ? " Not Available"
                    : client.regulator_registration_id}
                </p>
                <p>
                  Direct Tax ID:{" "}
                  {client.direct_tax_id === ""
                    ? " Not Available"
                    : client.direct_tax_id}
                </p>
                <p>
                  Indirect Tax ID:{" "}
                  {client.indirect_tax_id === ""
                    ? " Not Available"
                    : client.indirect_tax_id}
                </p>
                <p>
                  Company Website URL:
                  {client.company_website_url === "" ? (
                    " Not Available"
                  ) : (
                    <a
                      href={client.company_website_url}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "black",
                        marginLeft: "2%",
                      }}
                    >
                      {client.company_website_url}
                    </a>
                  )}
                </p>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h3 style={{ marginTop: "2%", fontSize: 18 }}>
                  Contact Person Details
                </h3>
                <p>
                  Name :{" "}
                  {client.contact_person_name
                    ? client.contact_person_name
                    : "Not Available"}
                </p>
                <p>
                  Designation :{" "}
                  {client.designation_contact_person
                    ? client.designation_contact_person
                    : "Not Availble"}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", width: "50%" }}
            >
              <h3 style={{ fontSize: 18 }}>Address Details</h3>
              <p>Address : {addressData?.address_line1} </p>
              <p>
                {addressData?.address_line2
                  ? addressData?.address_line2
                  : "Not Available"}
                ,{" "}
                {addressData?.address_line3
                  ? addressData?.address_line3
                  : "Not Available"}
              </p>
              <p>City: {addressData?.city}</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "2%",
                width: "50%",
              }}
            >
              <p>District: {addressData?.district_county}</p>
              <p>State: {addressData?.state_province_text}</p>
              <p>
                Pincode:{" "}
                {addressData?.pincode ? addressData?.pincode : "Not Available"}
              </p>
            </div>
          </div>
        </div>
      </div>

      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "2%",
          fontSize: "30px",
          color: "#A3020C",
        }}
      >
        Funds for {client.client_name}
      </h1>
      <Table
        style={{
          display: "block",
          justifyContent: "center",
          width: "95%",
          margin: "0 auto",
        }}
        rowKey={(record) => record.index}
        columns={columns}
        dataSource={fundTable}
        bordered
        scroll={{
          y: 400,
        }}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <div style={{ marginBottom: "5%" }}></div>
    </>
  );
};

export default Profile;
