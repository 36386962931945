import React, { useState } from "react";
import "antd/dist/antd.min.css";
import { Input, Form, Button, Modal } from "antd";
import { groupBy } from "lodash";
import { useStateContext } from "../../../contexts/ContextProvider";
import QueryProfileData from "../QueryDetails/QueryProfileData";
import { Link } from "react-router-dom";
import axios from "axios";
const SuggestedQueryList = (props) => {
  const { apiBaseUrl, client_id, user_id } = useStateContext();
  const data = props.searchFields;
  const groupedData = groupBy(data, "search_fieldid");
  const [inputs, setInputs] = useState([]);
  const [selectedQueryData, setSelectedQueryData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const scrollDownAfterSearch = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 10);
  };

  const searchAdvanceQuery = async () => {
    let typeofTransaction;
    let apiUrl = "";
    const apiData = inputs
      .map((input, subId) => ({ input, subId }))
      .filter(
        ({ input }) => input !== undefined && input !== null && input !== ""
      )
      .map(({ input, subId }) => ({
        search_sub_fieldid: subId,
        value: input,
      }));
    typeofTransaction = apiData.some(
      ({ search_sub_fieldid }) => search_sub_fieldid === 17
    )
      ? false
      : true;
    if (
      client_id === null ||
      client_id === undefined ||
      client_id === "" ||
      client_id === "null"
    ) {
      apiUrl = `${apiBaseUrl}/VAccount/getAdvancedSearchData/${user_id}?type=${typeofTransaction}`;
    } else {
      apiUrl = `${apiBaseUrl}/VAccount/getAdvancedSearchData/${user_id}?clientid=${client_id}&type=${typeofTransaction}`;
    }
    const uniqueApplicationNumbers = new Set();

    await axios
      .post(apiUrl, apiData)
      .then((response) => {
        const responseData = response.data.responseData;

        responseData.forEach((item) => {
          const applicationNumber = item.applicationnumber;
          if (!uniqueApplicationNumbers.has(applicationNumber)) {
            uniqueApplicationNumbers.add(applicationNumber);
            setSelectedQueryData((prevData) => [...prevData, item]);
          }
        });

        setShowTable(true);
        scrollDownAfterSearch();
        setIsModalVisible(false);

        if (responseData?.length === 0) {
          setIsModalVisible(true);
          setShowTable(false);
        }
      })
      .catch((error) => {
        setShowTable(false);
        setIsModalVisible(true);
      });
  };

  const handleInputChange = (index, value, subId) => {
    const newInputs = [...inputs];
    newInputs[subId] = value;
    setInputs(newInputs);
  };

  const renderInputFields = (items) => {
    const suggestedItems = items.filter(
      (item) => item.search_type === "Suggested"
    );
    if (suggestedItems.length === 0) {
      return null;
    }

    const fields = {};
    suggestedItems.forEach((item) => {
      const { search_sub_fieldid, search_sub_field_name } = item;
      if (!fields[search_sub_fieldid]) {
        fields[search_sub_fieldid] = {
          search_sub_fieldid,
          search_sub_field_name,
          inputs: [],
        };
      }
      fields[search_sub_fieldid].inputs.push(item);
    });

    const inputsArr = Object.values(fields)
      .map((field) => field.inputs)
      .flat();

    return (
      <div className="dark:text-gray-200 dark:bg-secondary-dark-bg rounded-md">
        {inputsArr.map((input, index) => (
          <div className="flex flex-wrap">
            <div key={input.search_sub_fieldid} className="flex-1">
              <label className="font-medium">
                {input.search_sub_field_name}:
              </label>
              <div>
                <Form.Item name={input.search_sub_field_name} className="h-4">
                  <Input
                    placeholder={`Enter ${input.search_sub_field_name}`}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        e.target.value,
                        input.search_sub_fieldid
                      )
                    }
                    value={inputs[input.search_sub_fieldid]}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          fontSize: "30px",
          color: "#A3020C",
        }}
      >
        Investor Query
      </h1>
      <div className="flex items-center justify-center">
        <div className="grid grid-cols-1 w-96">
          <div className="bg-validus-blue text-white font-semibold text-base py-1 px-1 rounded mb-4">
            Suggested Search Fields
          </div>
          {Object.entries(groupedData).map(([fieldId, items]) =>
            renderInputFields(items)
          )}
        </div>
      </div>
      <h1
        style={{
          display: "flex",
          justifyContent: "right",
          fontSize: "16px",
          marginRight: "16%",
        }}
      >
        <Link
          to={{ pathname: `/AdvancedQuerySelection` }}
          state={{ searchData: data }}
          className="text-validus-blue underline"
        >
          Advanced Query
        </Link>
      </h1>
      <div className="flex justify-center mb-10">
        <Button
          onClick={searchAdvanceQuery}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "80px",
            height: "33px",
            backgroundColor: "#A3020C",
            color: "white",
            borderRadius: 5,
            marginTop: 30,
          }}
        >
          Search
        </Button>
      </div>

      <div>
        <Modal
          open={isModalVisible}
          onOk={handleOk}
          closable={false}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <p>No data found. Please Enter Correct Details.</p>
        </Modal>
      </div>

      {showTable && (
        <>
          <QueryProfileData data={selectedQueryData} />
        </>
      )}
    </div>
  );
};
export default SuggestedQueryList;
