import React, { useState, useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { Table, Button, Modal, Input, Collapse } from "antd";
import moment from "moment";
import axios from "axios";

const { Panel } = Collapse;

const DocumentUpload = () => {
  const { apiBaseUrl, client_code, user_id } = useStateContext();
  const [toggleState, setToggleState] = useState(1);
  const [dematdocumentsTableData, setDematDocumentsTableData] = useState([]);
  const [corporateDocumentsTableData, setCorporateDocumentsTableData] =
    useState([]);
  const [otherDocumentsTableData, setOtherDocumentsTableData] = useState([]);
  const [documentType, setDocumentType] = useState();
  const [documentName, setDocumentName] = useState();
  const [file, setFile] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [s3Url, setS3Url] = useState();

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    getVaults();
  };

  const getVaults = () => {
    setIsModalOpen(false);
    fetch(`${apiBaseUrl}/VTransaction/GetVaultDetails/${client_code}`)
      .then((response) => response.json())
      .then((data) => {
        const demat = data.responseData.filter(
          (item) => item.document_type === "Demat"
        );
        setDematDocumentsTableData(demat);

        const corporateDocuments = data.responseData.filter(
          (ele) => ele.document_type === "Corporate"
        );
        setCorporateDocumentsTableData(corporateDocuments);

        const otherDocuments = data.responseData.filter(
          (ele) => ele.document_type === "Others"
        );
        setOtherDocumentsTableData(otherDocuments);
      });
  };

  const dematColumns = [
    {
      title: "Document Type",
      dataIndex: "document_type",
    },
    {
      title: "Document Name",
      dataIndex: "document_name",
    },
    {
      title: "Document Date",
      dataIndex: "creation_date",
      render: (_, record) => {
        let date = moment(record.creation_date).format("DD/MM/YYYY");
        return <div>{date}</div>;
      },
    },
    {
      title: "Document Status",
      dataIndex: "storage_category",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        console.log(record, "record");
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                width: "60px",
                height: "35px",
                backgroundColor: "#A3020C",
                color: "white",
              }}
              onClick={
                record.s3_path
                  ? () =>
                      window.open(
                        record.s3_path.replace(
                          "&export=download",
                          "&export=view"
                        )
                      )
                  : () => alert("No document found")
              }
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];

  const corporateColumns = [
    {
      title: "Document Type",
      dataIndex: "document_type",
    },
    {
      title: "Document Name",
      dataIndex: "document_name",
    },
    {
      title: "Document Date",
      dataIndex: "creation_date",
      render: (_, record) => {
        let date = moment(record.creation_date).format("DD/MM/YYYY");
        return <div>{date}</div>;
      },
    },
    {
      title: "Document Status",
      dataIndex: "storage_category",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                width: "60px",
                height: "35px",
                backgroundColor: "#A3020C",
                color: "white",
              }}
              onClick={
                record.s3_path !== ""
                  ? () =>
                      window.open(
                        record.s3_path.replace(
                          "&export=download",
                          "&export=view"
                        )
                      )
                  : () => alert("No document found")
              }
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];

  const otherColumns = [
    {
      title: "Document Type",
      dataIndex: "document_type",
    },
    {
      title: "Document Name",
      dataIndex: "document_name",
    },
    {
      title: "Document Date",
      dataIndex: "creation_date",
      render: (_, record) => {
        let date = moment(record.creation_date).format("DD/MM/YYYY");
        return <div>{date}</div>;
      },
    },
    {
      title: "Document Status",
      dataIndex: "storage_category",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                width: "60px",
                height: "35px",
                backgroundColor: "#A3020C",
                color: "white",
              }}
              onClick={
                record.s3_path !== ""
                  ? () =>
                      window.open(
                        record.s3_path.replace(
                          "&export=download",
                          "&export=view"
                        )
                      )
                  : () => alert("No document found")
              }
            >
              View
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getVaults();
  }, [client_code]);

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
    handleUploadDocument(file);
  };

  const fileUrl = "client-bucket_" + client_code;

  const handleUploadDocument = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        `${apiBaseUrl}/AwsS3/UploadDocToGdrive?folders=${fileUrl}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setS3Url(response.data?.WebContentLink);

      const temp = [];
      temp.push(response.data?.WebContentLink);
      saveUploadedDocuments(file.type.split("/")[1]);
    } catch (error) {
      console.log(error);
    }
  };

  const saveUploadedDocuments = async (fileType) => {
    console.log(Date().now, "date");
    const data = {
      vault_id: 0,
      entity_id: 0,
      document_type: `${documentType}`,
      document_name: `${documentName}`,
      entity_reference_number: `${client_code}`,
      storage_category: "Inward",
      s3_path: `${s3Url}`,
      created_by: user_id,
      creation_date: new Date(),
      isactive: true,
      file_type: fileType,
      updated_by: user_id,
      updation_date: new Date(),
      transaction_reference_number: `${client_code}`,
      document_type_id: 114,
    };

    axios
      .post(`${apiBaseUrl}/VTransaction/SaveVaultDetails`, data)
      .then((response) => {
        console.log(response.data.responseData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          fontSize: "30px",
          color: "#A3020C",
          marginTop: "2%",
        }}
      >
        Client Documents
      </h1>

      <div
        className="container"
        style={{
          display: "flex",
          marginTop: "2%",
          marginLeft: "2%",
          marginRight: "3%",
        }}
      >
        <div className="bloc-tabs">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(1)}
          >
            Demat Documents
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(2)}
          >
            Corporate Documents
          </button>
          <button
            className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(3)}
          >
            Other Documents
          </button>
        </div>
        <div className="content-tabs">
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <div className="row">
              <div className="col-md-6">
                <div
                  style={{
                    overflow: "auto",
                  }}
                >
                  <Table
                    style={{
                      display: "block",
                      justifyContent: "center",
                      alignContent: "center",
                      width: "98%",
                    }}
                    columns={dematColumns}
                    dataSource={dematdocumentsTableData}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          >
            <div className="row">
              <div className="col-md-6">
                <div
                  style={{
                    overflow: "auto",
                  }}
                >
                  <Table
                    style={{
                      display: "block",
                      justifyContent: "center",
                      alignContent: "center",
                      width: "98%",
                    }}
                    columns={corporateColumns}
                    dataSource={corporateDocumentsTableData}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              toggleState === 3 ? "content  active-content" : "content"
            }
          >
            <div className="row">
              <div className="col-md-6">
                <Table
                  style={{
                    display: "block",
                    justifyContent: "center",
                    alignContent: "center",
                    width: "98%",
                  }}
                  columns={otherColumns}
                  dataSource={otherDocumentsTableData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Document Upload"
        open={isModalOpen}
        onOk={handleClose}
        onCancel={handleClose}
        footer={[
          <Button key="submit" type="primary" onClick={handleClose}>
            Submit
          </Button>,
        ]}
      >
        <Collapse
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Panel header="Please Upload Documents" key="1">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Input
                placeholder="Document Type"
                style={{ width: "200px" }}
                onChange={(e) => {
                  console.log(e.target.value, "type");
                  setDocumentType(e.target.value);
                }}
              />
              <Input
                placeholder="Document Name"
                style={{ width: "200px" }}
                onChange={(e) => {
                  console.log(e.target.value, "name");
                  setDocumentName(e.target.value);
                }}
              />
              <input
                type="file"
                style={{ width: "200px", height: "35px" }}
                onChange={(e) => {
                  console.log(e.target.files[0].name, "file");
                  handleFileInput(e);
                }}
              />

              <Button
                style={{
                  width: "100px",
                  height: "35px",
                  backgroundColor: "#A3020C",
                  color: "white",
                }}
              >
                View
              </Button>
            </div>
          </Panel>
        </Collapse>
      </Modal>
    </div>
  );
};

export default DocumentUpload;
