import React from 'react'
import './leftnav.css'

const LeftNav = () => {
  return (
    <div style={{width: '75%'}}  className='background'>
      <div className='AmcBackground'></div>
      <div className='footer'>Looking for something else?</div>
      <div className='footer-1'>Call us on
        <span className='ml-2'>
          <a href='tel:91002 22569' style={{ color: '#fff', textDecoration: 'none' }}>
          91002 22569
          </a>
        </span>
      </div>
      <div className='footer-2'>Write to us at
        <span  className='ml-2'>
          <a href='mailto:amccare@validusfintech.com' style={{ color: '#fff', textDecoration: 'none' }}>
          amccare@validusfintech.com
          </a>
        </span>
      </div>
    </div>
  )
}

export default LeftNav