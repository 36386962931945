import React from "react";

const Classes = ({ data }) => {
  const uniqueFees = data?.fundfeelist.filter((item, index, array) => {
    // Find the index of the first occurrence of the current item in the array
    const firstIndex = array.findIndex(
      (i) =>
        i.fee_calculation_type === item.fee_calculation_type &&
        i.fee_percentage === item.fee_percentage
    );

    // Include the item in the new array only if its index is the same as the first index
    return index === firstIndex;
  });
  return (
    <div>
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          fontSize: "30px",
          color: "#A3020C",
        }}
      >
        Class {data?.investment_class_description}
      </h1>
      <div
        style={{
          gap: "20px",
          padding: "20px",
          backgroundColor: "#f5f5f5",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          maxWidth: "1000px",
          margin: "0 auto",
          marginBottom: "7px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h2>Class Details</h2>
            <p>Class Name: {data.investment_class_description}</p>
            <p>Class Code: {data.investment_class_code}</p>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h2>Fund Details</h2>
            <p>Fund Name : {data.fund_description}</p>
            <p> Setup Fee : {data.setfeeon_comt === "Y" ? "Yes" : "No"}</p>
          </div>
        </div>
        <h2>Fee Details</h2>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "20px",
            width: "100%",
          }}
        >
          {uniqueFees?.map((item) => {
            return (
              <div key={item.fund_fee_id}>
                <p>Fee Type: {item.fee_calculation_type}</p>
                <p>Fee Percentage: {item.fee_percentage}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Classes;
