import React, { useState, useEffect } from "react";
import { Button, Select, Table, Input, Space } from "antd";
import LoadingScreen from "react-loading-screen";
import { useStateContext } from "../../contexts/ContextProvider";
import moment from "moment/moment";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { all } from "axios";

const Transactions = () => {
  const { apiBaseUrl, client_id } = useStateContext();
  const [loading, setLoading] = useState();
  const [schemeData, setSchemeData] = useState([]);
  const [selectedScheme, setSelectedScheme] = useState("");
  const [disableScheme, setDisableScheme] = useState(true);
  const [nctData, setNCTData] = useState([]);
  const [fundHouseDataLoaded, setFundHouseDataLoaded] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [disableGetButton, setDisableGetButton] = useState(true);

  const [drawdownData, setDdData] = useState([]);
  const [allTransactionsData, setAllTransactionsData] = useState([]);
  const [allTransactionsTableData, setAllTransactionsTableData] = useState([]);
  const [ddTableData, setDDTableData] = useState([]);

  const fetchFundData = async () => {
    fetch(`${apiBaseUrl}/VFund/GetFundbyCilentid/${client_id}`)
      .then((response) => response.json())
      .then((json) => {
        console.log(json.responseData);
        setSchemeData(json.responseData);
      })
      .catch((error) => console.log(`Error Loading Data : ${error.message}`))
      .finally(setFundHouseDataLoaded(true));
  };

  useEffect(() => {
    fetchFundData();
  }, []);

  const handleSelectScheme = (e) => {
    setSelectedScheme(e);
    setLoading(true);
    fetch(`${apiBaseUrl}/VNct/GetNctTransactions/${e}/${client_id}`)
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        let filteredData = json.responseData
          .filter((ele) => ele.nct_status !== "Closed")
          .reverse();

        console.log("filteredData", filteredData);
        setNCTData(filteredData);
      })
      .catch((error) => alert("something went error"));

    fetch(
      `${apiBaseUrl}/VDrawdown/FetchDrawdownDetails/${client_id}?Fund_id=${e}`
    )
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        console.log("dd Transactions", json.responseData);
        setDdData(json.responseData);
      })
      .catch((error) => alert("something went error"));

    fetch(
      `${apiBaseUrl}/VTransaction/GetTransactionsCommon/${client_id}?fundId=${e}`
    )
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        console.log("transaction", json.responseData);
        setAllTransactionsData(json.responseData);
      })
      .catch((error) => alert("something went error"));
    setDisableScheme(false);
    setDisableGetButton(false);
  };

  const fetchNctTransactionData = () => {
    setShowTable(true);
  };

  useEffect(() => {
    nctData.forEach((ele, index) => {
      ele.sno = index + 1;
      ele.nct_transaction_date = moment(ele.nct_transaction_date)
        .format("DD/MM/YYYY")
        .toString();
      ele.nct_status =
        ele.nct_status === "Open"
          ? "Pending"
          : ele.nct_status === "Closed"
          ? "Success"
          : ele.nct_status === "Resolved"
          ? "Rejected"
          : "Null";
    });
    setTableData(nctData);

    allTransactionsData.forEach((ele, index) => {
      ele.sno = index + 1;
      ele.transaction_ts = moment(ele.transaction_ts)
        .format("DD/MM/YYYY")
        .toString();
      ele.transaction_status =
        ele.transaction_status === "P"
          ? "Pending"
          : ele.transaction_status === "Y"
          ? "Success"
          : "Null";
    });
    setAllTransactionsTableData(allTransactionsData);

    drawdownData.forEach((e, index) => {
      e.sno = index + 1;
      e.drawdown_start_date = moment(e.drawdown_start_date)
        .format("DD/MM/YYYY")
        .toString();
      e.drawdown_end_date = moment(e.drawdown_end_date)
        .format("DD/MM/YYYY")
        .toString();
      e.transaction_status =
        e.transaction_status === "P"
          ? "Pending"
          : e.transaction_status === "Y"
          ? "Success"
          : "Null";
    });
    setDDTableData(drawdownData);
  }, [allTransactionsData, nctData, drawdownData]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [filteredDrawdownInfo, setFilteredDrawdownInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const onDDChange = (_, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const onNCTChange = (_, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const onAllTransChange = (_, filters, sorter) => {
    setFilteredDrawdownInfo(filters);
    setSortedInfo(sorter);
  };

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search Here`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
              setSearchText("");
              setSearchedColumn("");
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90 }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),

    filterIcon: (filtered) => (
      <span className="ant-table-filter-icon-container">
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      </span>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const filterAllTransactionsData = (allTransactionsData) =>
    allTransactionsData.map((item) => ({
      key: item,
      value: item,
      text: item,
    }));

  const filterDDTableData = (drawdownData) =>
    drawdownData.map((item) => ({
      key: item,
      value: item,
      text: item,
    }));

  const filterNCTData = (tableData) =>
    tableData.map((item) => ({
      key: item,
      value: item,
      text: item,
    }));

  const columnsNCT = [
    {
      title: "S. No",
      dataIndex: "sno",
      align: "center",
      key: "1",
      width: 68,
    },
    {
      title: (
        <>
          Request
          <br />
          Registration
          <br />
          Date
        </>
      ),
      dataIndex: "nct_transaction_date",
      align: "center",
      key: "4",
    },
    {
      title: "Account No.",
      dataIndex: "account_no",
      align: "center",
      key: "3",
      ...getColumnSearchProps("account_no"),
    },
    {
      title: "Ticket No.",
      dataIndex: "nct_ih_number",
      align: "center",
      key: "2",
      ...getColumnSearchProps("nct_ih_number"),
    },
    {
      title: (
        <>
          Service
          <br />
          Request
          <br />
          Category
        </>
      ),
      dataIndex: "nct_category_description",
      align: "center",
      key: "5",
      filters: filterNCTData(
        tableData
          .map((item) => item.nct_category_description)

          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      onFilter: (value, record) =>
        record.nct_category_description.indexOf(value) === 0,
    },
    {
      title: (
        <>
          Service
          <br />
          Request
        </>
      ),
      dataIndex: "nct_subject_description",
      align: "center",
      key: "6",
      ...getColumnSearchProps("nct_subject_description"),
    },
  ];

  const columnsDrawdown = [
    {
      title: "S. No",
      dataIndex: "sno",
      align: "center",
      key: "1",
    },
    {
      title: "Start Date",
      dataIndex: "drawdown_start_date",
      align: "center",
      key: "2",
    },
    {
      title: "End Date",
      dataIndex: "drawdown_end_date",
      align: "center",
      key: "3",
    },
    {
      title: (
        <>
          Transaction
          <br />
          Amount
        </>
      ),
      dataIndex: "transaction_amount",
      align: "center",
      key: "4",
    },
    {
      title: (
        <>
          Total
          <br />
          Amount
        </>
      ),
      dataIndex: "total_amount",
      align: "center",
      key: "5",
    },
    {
      title: (
        <>
          Account
          <br />
          Number
        </>
      ),
      dataIndex: "account_number",
      align: "center",
      key: "5",
      ...getColumnSearchProps("account_number"),
    },
    // {
    //   title: "Status",
    //   dataIndex: "transaction_status",
    //   align: "center",
    //   key: "6",
    // }
  ];

  const columnsAll = [
    {
      title: "S. No",
      dataIndex: "sno",
      align: "center",
      key: "1",
      responsive: ["md"],
    },
    {
      title: (
        <>
          Transaction
          <br />
          Date
        </>
      ),
      dataIndex: "transaction_ts",
      align: "center",
      key: "2",
      responsive: ["md"],
    },
    {
      title: (
        <>
          Investor
          <br />
          Name
        </>
      ),
      dataIndex: "investor_name",
      align: "center",
      key: "3",
      responsive: ["md"],
      ...getColumnSearchProps("nct_subject_description"),
    },
    {
      title: "Account No.",
      dataIndex: "account_number",
      align: "center",
      key: "4",
      responsive: ["md"],
      ...getColumnSearchProps("account_number"),
    },
    {
      title: (
        <>
          Transaction
          <br />
          Type
        </>
      ),
      dataIndex: "transaction_type",
      align: "center",
      key: "5",
      responsive: ["md"],
      filters: filterAllTransactionsData(
        allTransactionsTableData
          .map((item) => item.transaction_type)

          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      onFilter: (value, record) => record.transaction_type.indexOf(value) === 0,
    },
    {
      title: (
        <>
          Transaction
          <br />
          Status
        </>
      ),
      dataIndex: "transaction_status",
      align: "center",
      key: "6",
      responsive: ["md"],
      filters: filterAllTransactionsData(
        allTransactionsTableData
          .map((item) => item.transaction_status)

          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      onFilter: (value, record) =>
        record.transaction_status.indexOf(value) === 0,
    },
  ];

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [selectedOption, setSelectedOption] = useState("allTransactions");

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <div
      style={{
        zIndex: 1000,
      }}
    >
      {fundHouseDataLoaded ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
            }}
          >
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                fontSize: "30px",
                color: "#A3020C",
              }}
            >
              All Transactions
            </h1>
          </div>
          <div style={{ margin: "0 auto", width: "70%", alignSelf: "center" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 40,
              }}
            >
              <Select
                showSearch
                placeholder="Select Fund"
                optionFilterProp="children"
                onChange={(e) => handleSelectScheme(e)}
                style={{
                  fontSize: "14px",
                  color: "#A3020C",
                  width: "200px",
                }}
              >
                {schemeData.map((ele, index) => (
                  <Select.Option key={index} value={ele.fund_ID}>
                    {ele.fund_Description}
                  </Select.Option>
                ))}
              </Select>
              <Button
                disabled={disableGetButton}
                onClick={fetchNctTransactionData}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "140px",
                  height: "33px",
                  backgroundColor: "#A3020C",
                  color: "white",
                  borderRadius: 5,
                }}
              >
                Get
              </Button>
              <Button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "140px",
                  height: "33px",
                  backgroundColor: "#A3020C",
                  color: "white",
                  borderRadius: 5,
                }}
                onClick={() => window.location.reload(false)}
              >
                Reset
              </Button>
            </div>
          </div>
          {showTable ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                margin: "0 auto",
                width: "98%",
                marginTop: "20px",
                marginBottom: "20px",
                height: "70%",
              }}
            >
              <div className="container">
                <div className="bloc-tabs">
                  <button
                    className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(1)}
                  >
                    All Transactions
                  </button>
                  <button
                    className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(2)}
                  >
                    Drawdowns
                  </button>
                  <button
                    className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(3)}
                  >
                    Service Requests
                  </button>
                </div>
                <div className="content-tabs">
                  <div
                    className={
                      toggleState === 1 ? "content  active-content" : "content"
                    }
                  >
                    <h2>
                      <u>Transactions</u>
                    </h2>
                    <Table
                      style={{
                        display: "block",
                        justifyContent: "center",
                        marginTop: "3%",
                        width: "100%",
                      }}
                      columns={columnsAll}
                      dataSource={allTransactionsTableData}
                      onChange={onAllTransChange}
                      pagination={{
                        pageSize: 10,
                      }}
                      bordered
                    />
                  </div>
                  <div
                    className={
                      toggleState === 2 ? "content  active-content" : "content"
                    }
                  >
                    <h2>
                      <u>Drawdown Details</u>
                    </h2>
                    <Table
                      style={{
                        display: "block",
                        justifyContent: "center",
                        marginTop: "3%",
                        width: "100%",
                      }}
                      onChange={onDDChange}
                      columns={columnsDrawdown}
                      dataSource={ddTableData}
                      pagination={{
                        pageSize: 10,
                      }}
                      bordered
                    />
                  </div>
                  <div
                    className={
                      toggleState === 3 ? "content  active-content" : "content"
                    }
                  >
                    <h2>
                      <u>Service Request Details</u>
                    </h2>
                    <Table
                      style={{
                        display: "block",
                        justifyContent: "center",
                        marginTop: "3%",
                        width: "100%",
                      }}
                      columns={columnsNCT}
                      dataSource={tableData}
                      onChange={onNCTChange}
                      pagination={{
                        pageSize: 10,
                      }}
                      bordered
                      // expandable={{
                      //   expandedRowRender: (record) => (
                      //     <p
                      //       style={{
                      //         margin: 0,
                      //       }}
                      //     >
                      //       <span>Status:
                      //         <span>{record.nct_status}</span>
                      //       </span>
                      //     </p>
                      //   ),
                      //   rowExpandable: (record) => record.sno
                      // }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
              role="alert"
            >
              Please Select Fund
            </div>
          )}
        </>
      ) : (
        <LoadingScreen
          loading={true}
          bgColor="transparent"
          spinnerColor="#000"
          textColor="#000"
          text="Loading..."
        >
          <h1>Loading...</h1>
        </LoadingScreen>
      )}
    </div>
  );
};

export default Transactions;
