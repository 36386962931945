import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { useStateContext } from "../contexts/ContextProvider";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
const FundPage = ({ data }) => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [classTable, setClassTable] = useState([]);
  const [fund, setFund] = useState([{}]);
  const { apiBaseUrl, setClassTableData } = useStateContext();
  const onSelectClass = (record) => {
    sessionStorage.setItem("classTableData", JSON.stringify(record));
    setClassTableData(record);
  };

  const columns = [
    {
      title: "S. No",
      dataIndex: "sno",
      align: "center",
      width: 50,
    },
    {
      title: "Class Name",
      dataIndex: "investment_class_description",
      align: "center",
      width: 180,
    },
    {
      title: "Class Code",
      dataIndex: "investment_class_code",
      align: "center",
      width: 90,
    },
    {
      title: "View Details",
      dataIndex: "",
      align: "center",
      width: 130,
      render: (_, record) => {
        return (
          <div style={{ alignSelf: "center", marginLeft: "32%" }}>
            <Button
              onClick={() => onSelectClass(record)}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "80px",
                height: "33px",
                backgroundColor: "#A3020C",
                color: "white",
                borderRadius: 5,
              }}
            >
              <Link to={{ pathname: `/Classes` }}>View</Link>
            </Button>
          </div>
        );
      },
    },
  ];

  const showClasses = () => {
    axios
      .get(`${apiBaseUrl}/VClass/GetClassbyFundid?fundid=${data?.fund_ID}`)
      .then((res) => {
        setTableData(res.data.responseData);
        setLoading(false);
      })
      .catch((error) => alert("something went error"));
  };

  const filteredData = tableData.filter((obj) => obj.fund_id === data?.fund_ID);
  useEffect(() => {
    showClasses();
  }, []);

  useEffect(() => {
    filteredData.forEach((ele, index) => {
      ele.sno = index + 1;
    });
    setClassTable(filteredData);
  }, [filteredData]);

  const showFunds = () => {
    axios
      .get(`${apiBaseUrl}/VFund/GetFundbyCilentid/${data?.client_id}`)
      .then((res) => {
        res.data.responseData.forEach((ele, index) => {
          ele.sno = index + 1;
        });
        setFund(res.data.responseData[0]);
      })
      .catch((error) => alert("something went error"));
  };

  useEffect(() => {
    showFunds();
  }, []);

  return (
    <div>
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          fontSize: "30px",
          color: "#A3020C",
        }}
      >
        {data?.fund_Description}
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          padding: "20px",
          backgroundColor: "#f5f5f5",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          maxWidth: "1000px",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <h2 style={{ fontSize: "16px" }}>Fund Details</h2>
            <p>Fund Name : {fund.fund_Description}</p>
            <p>Fund Code : {fund.code}</p>
            {/* <p>Client Name : {fund.}</p> */}
            <p>Fund Type : {fund.fund_type_description}</p>
            <p>Fund Category : {fund.fund_category_description}</p>
            <p>Fund Sub Category : {fund.fund_sub_catergory_description}</p>

            <h2 style={{ fontSize: "16px" }}>Investor Details</h2>
            <p>Minimum no.of Investors : {fund.minimum_Investor_Count}</p>
            <p>Maximum no.of Investors : {fund.maximum_Investor_Count}</p>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2 style={{ fontSize: "16px" }}>Registration Details</h2>
              <p>SEBI Registration No : {fund.regulator_Registration_Number}</p>
              <p>
                Maturity Date :{" "}
                {moment(fund.maturity_Date).format("DD-MM-YYYY")}
              </p>
              <p>
                Stampduty Payable by Investor :{" "}
                {fund.stampduty_payable_investor === "Y" ? "Yes" : "No"}
              </p>

              <p>Face Value: {fund.naV_Frequency}</p>
              <p>Units Round Off : {fund.unit_Round_Type} </p>
              <p>
                Purchase Cut Off :{" "}
                {fund.purchase_cutoff_time > "12:00"
                  ? fund.purchase_cutoff_time + " pm"
                  : fund.purchase_cutoff_time + " am"}
              </p>
              <p>
                Redemption Cut Off :
                {fund.redemption_cutoff_time > "12:00"
                  ? fund.redemption_cutoff_time + " pm"
                  : fund.redemption_cutoff_time + " am"}
              </p>
              <p>
                {" "}
                Lock in Period :{" "}
                {fund.lock_in_period !== "" ? fund.lock_in_period : "NA"}
              </p>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}></div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
            }}
          ></div>
        </div>
      </div>

      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "2%",
          fontSize: "30px",
          color: "#A3020C",
        }}
      >
        Classes for {data?.code_Description}
      </h1>
      <Table
        style={{
          display: "block",
          justifyContent: "center",
          margin: "0 auto",
          width: "93%",
        }}
        pagination={5}
        rowKey={(record) => record.index}
        columns={columns}
        dataSource={classTable}
        bordered
        scroll={{
          y: 400,
        }}
        loading={loading}
      />
    </div>
  );
};

export default FundPage;
