import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import { Table, Button, Select, DatePicker } from "antd";
import moment from "moment";
import DtrReports from "./DailyTransactionReports";
import UcrReports from "./UnitCapitalReports";
import UhrReports from "./UnitHoldingReports";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  selectionReportData,
  selectionAccountData,
} from "./selectionDataReport";
import TransactionRegisterReports from "./TransactionRegisterReports";
import CreditMarkingReports from "./CreditMarkingReports";
import NavReports from "./NavReports";
import NctReports from "./NctReports";
import { set } from "lodash";

const ReportGeneration = (props) => {
  console.log("props", props.clientId);
  const { apiBaseUrl, formatNumberWithCommas, userClientID, client_id } =
    useStateContext();
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [fundHouseData, setFundHouseData] = useState([]);
  const [schemeData, setSchemeData] = useState([]);
  const [selectedFundHouse, setSelectedFundHouse] = useState("");
  console.log("selectedFundHouse#$%^&^%$%^&^%$%^&^%$%^&", selectedFundHouse);
  const [selectedScheme, setSelectedScheme] = useState("");
  const [disableScheme, setDisableScheme] = useState(true);
  const [transactionData, setTransactionData] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState([]);
  const [selectedToDate, setSelectedToDate] = useState([]);
  const [dtrTableData, setDtrTableData] = useState([]);
  const [ucrTableData, setUcrTableData] = useState([]);
  const [uhrTableData, setUhrTableData] = useState([]);
  const [nctTableData, setNctTableData] = useState([]);
  const [transactionRegisterData, setTransactionRegisterData] = useState([]);
  const [creditMarkingData, setCreditMarkingData] = useState([]);
  const [navReportData, setNavReportData] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const [reportType, setReportType] = useState();
  const [reportData, setReportData] = useState(false);

  const dateManipulator = (string) => {
    string = string.slice(0, -9);
    let date = string.slice(-2);
    let year = string.substring(0, 4);
    let month = string.substring(5, 7);
    string = date + "/" + " " + month + "/" + " " + year;
    return string;
  };

  useEffect(() => {
    try {
      transactionData.forEach((ele, index) => {
        ele.sno = index + 1;
        ele.creation_date
          ? (ele.creation_date = dateManipulator(ele.creation_date))
          : (ele.creation_date = "No Date Present");
        ele.fund_nav_date
          ? (ele.fund_nav_date = dateManipulator(ele.fund_nav_date))
          : (ele.fund_nav_date = "No Date Present");
        ele.nav_date && ele.nav_date !== "NA"
          ? (ele.nav_date = dateManipulator(ele.nav_date))
          : (ele.nav_date = "No Date Present");
        ele.transaction_date
          ? (ele.transaction_date = dateManipulator(ele.transaction_date))
          : (ele.transaction_date = "No Date Present");
        ele.paymentdate
          ? (ele.paymentdate = dateManipulator(ele.paymentdate))
          : (ele.paymentdate = "No Date Present");
        ele.endoreseddate
          ? (ele.endoreseddate = dateManipulator(ele.endoreseddate))
          : (ele.endoreseddate = "No Date Present");
        ele.chequedate
          ? (ele.chequedate = dateManipulator(ele.chequedate))
          : (ele.chequedate = "No Date Present");
        ele.processed_date
          ? (ele.processed_date = dateManipulator(ele.processed_date))
          : (ele.processed_date = "No Date Present");
        ele.primary_holder_dob
          ? (ele.primary_holder_dob = moment(
              ele.primary_holder_dob,
              "DD/MM/YYYY hh:mm:ss a"
            )
              .format("DD/MM/YYYY")
              .toString())
          : (ele.primary_holder_dob = "No Date Present");
        ele.endorsement_flag =
          ele.endorsement_flag === "Y"
            ? "Success"
            : ele.endorsement_flag === "P"
            ? "Pending"
            : "Null";
        ele.remark
          ? (ele.remark = ele.remark)
          : (ele.remark = "No Remarks Present");
        ele.status =
          ele.status === "Open"
            ? "Pending"
            : ele.status === "Closed"
            ? "Success"
            : ele.status === "Resolved"
            ? "Rejected"
            : "Null";
        ele.nav_value
          ? (ele.nav_value = formatNumberWithCommas(ele.nav_value))
          : (ele.nav_value = "No Amount Present");
        ele.contributionamount
          ? (ele.contributionamount = formatNumberWithCommas(
              ele.contributionamount
            ))
          : (ele.contributionamount = "No Amount Present");
        ele.transactionamount
          ? (ele.transactionamount = formatNumberWithCommas(
              ele.transactionamount
            ))
          : (ele.transactionamount = "No Amount Present");
        ele.committment_amount
          ? (ele.committment_amount = formatNumberWithCommas(
              ele.committment_amount
            ))
          : (ele.committment_amount = "No Amount Present");
      });
      //console.log("Report Generation****************",transactionData);
      setDtrTableData(transactionData);
      setUcrTableData(transactionData);
      setUhrTableData(transactionData);
      setNctTableData(transactionData);
      setTransactionRegisterData(transactionData);
      setCreditMarkingData(transactionData);
      setNavReportData(transactionData);
    } catch (err) {
      // console.log(err);
      alert("There was an Error Loading The Data");
      setTransactionData([]);
      setShowTable(false);
      setLoading(false);
    }
  }, [transactionData]);
  useEffect(() => {
    setSelectedFundHouse(client_id);
    fetchFundData(client_id);
  }, [reportType]);

  const fetchFundData = async (data) => {
    fetch(`${apiBaseUrl}/VFund/GetFundbyCilentid/${data}`)
      .then((response) => response.json())
      .then((json) => {
        setSchemeData(json.responseData);
      })
      .catch((error) => console.log(`Error Loading Data : ${error.message}`));
  };

  const fetchFundHouseData = async () => {
    const apiUrl = client_id
      ? `${apiBaseUrl}/VClient/GetClients?clientid=${client_id}`
      : `${apiBaseUrl}/VClient/GetClients`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((json) => setFundHouseData(json.responseData))
      .catch((error) => {
        console.log(error);
        alert(`Error Loading Data : ${error.message}`);
        //setIsCrashed(true);
      });
  };

  useEffect(() => {
    fetchFundHouseData(); // this is for fetching fund house data from api for client data selection
  }, []);

  //////////////////////////////////////////////////////////////////

  const handleSelectReportType = (e) => {
    //console.log(e)
    setReportData(true);
    setReportType(e);
    setShowTable(false);
    setDisableScheme(true);
    setTransactionData([]);
    setSelectedFundHouse("");
    setSelectedScheme("");
  };

  const fetchData = (url) => {
    setLoading(true);
    setShowTable(true);
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setTransactionData(json.responseData, "transactionData");
        setLoading(false);
      })
      .catch((error) => alert(`Something went wrong`));
  };

  const fetchDtrData = () => {
    const url = `${apiBaseUrl}/VReports/GetDailyReport2/${selectedToDate}/${selectedFundHouse}/${selectedScheme}`;
    fetchData(url);
  };

  const fetchUcrData = () => {
    const url = `${apiBaseUrl}/VReports/GetUcr_data/${selectedFundHouse}/${selectedScheme}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchUhrData = () => {
    const url = `${apiBaseUrl}/VReports/GetUhr/${selectedFundHouse}/${selectedScheme}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchNctData = () => {
    const url = `${apiBaseUrl}/VReports/GetNctReport/${selectedFundHouse}/${selectedScheme}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchTransactionRegisterData = () => {
    const url = `${apiBaseUrl}/VReports/GetTransactionRegisterReport/${selectedFundHouse}/${selectedScheme}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchCreditMarkingData = () => {
    const url = `${apiBaseUrl}/VReports/GetCreditmarkingReport/${selectedFundHouse}/${selectedScheme}/${selectedToDate}`;
    fetchData(url);
  };

  const fetchNAVReportData = () => {
    const url = `${apiBaseUrl}/VReports/GetNavReport/${selectedFundHouse}/${selectedScheme}/${selectedToDate}`;
    fetchData(url);
  };

  // DTR column data
  const columnsDtr = [
    {
      title: "S. No",
      dataIndex: "sno",
      key: "1",
    },
    {
      title: "Name",
      dataIndex: "investor_name",
      key: "2",
    },
    {
      title: "Class",
      dataIndex: "investment_classname",
      key: "3",
    },
    {
      title: "Transaction Number",
      dataIndex: "ih_number",
      key: "4",
    },
    {
      title: "Amount",
      dataIndex: "committment_amount",
      key: "5",
    },
  ];

  // UCR column data
  const columnsUcr = [
    {
      title: "S. No",
      dataIndex: "sno",
    },
    {
      title: "Name",
      dataIndex: "fund_name",
    },
    {
      title: "Total Amount",
      dataIndex: "transactionamount",
    },
  ];

  // Uhr column data
  const columnsUhr = [
    {
      title: "S. No",
      dataIndex: "sno",
    },
    {
      title: "Name",
      dataIndex: "investorname",
    },
    {
      title: "Class",
      dataIndex: "class_name",
    },
    {
      title: "Transaction Number",
      dataIndex: "account_number",
    },
    {
      title: "Amount",
      dataIndex: "contributionamount",
    },
  ];

  // Nct column data
  const columnsNct = [
    {
      title: "S. No",
      dataIndex: "sno",
      responsive: ["md"],
    },
    {
      title: "Ticket No.",
      dataIndex: "erp_reference_ticket_num",
      responsive: ["md"],
    },
    {
      title: "Request Registration Date",
      dataIndex: "transaction_date",
      responsive: ["md"],
    },
    {
      title: "Account Number",
      dataIndex: "account_number",
      responsive: ["md"],
    },
    {
      title: "Investor Name",
      dataIndex: "investor_name",
      responsive: ["md"],
    },
    {
      title: "Service Request Category",
      dataIndex: "category_name",
      responsive: ["md"],
    },
    {
      title: "Service Request",
      dataIndex: "subject_name",
      responsive: ["md"],
    },
    {
      title: "Status",
      dataIndex: "status",
      responsive: ["md"],
    },
  ];

  // Transaction Register column data
  const columnsTransactionRegister = [
    {
      title: "S. No",
      dataIndex: "sno",
    },
    {
      title: "Account Number",
      dataIndex: "accountnumber",
    },
    {
      title: "Investor Name",
      dataIndex: "investor_name",
    },
    {
      title: "Transaction Number",
      dataIndex: "ihnumber",
    },
    {
      title: "Transaction Date",
      dataIndex: "transaction_date",
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type_name",
    },
  ];

  // CreditMarking column data
  const columnsCreditMarking = [
    {
      title: "S. No",
      dataIndex: "sno",
    },
    {
      title: "Account Number",
      dataIndex: "accountnumber",
    },
    {
      title: "Transaction Date",
      dataIndex: "transaction_date",
    },
    {
      title: "Transaction Number",
      dataIndex: "ihnumber",
    },
    {
      title: "Transaction Amount",
      dataIndex: "transactionamount",
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
    },
    {
      title: "Status",
      dataIndex: "endorsement_flag",
    },
  ];

  // CreditMarking column data
  const columnsNAVReport = [
    {
      title: "S. No",
      dataIndex: "sno",
    },
    {
      title: "Fund House",
      dataIndex: "client_name",
    },
    {
      title: "Scheme",
      dataIndex: "fund_name",
    },
    {
      title: "Investment Class",
      dataIndex: "class_name",
    },
    {
      title: "NAV Date",
      dataIndex: "nav_date",
    },
    {
      title: "NAV Value",
      dataIndex: "nav_value",
    },
  ];

  const handleChange = (_, filter, sorter, pagination, filters) => {
    // console.log('sorter', sorter);
    const { order, field } = sorter;
    setSortedInfo({ columnKey: field, order });
  };

  return (
    <div
      style={{
        zIndex: 1000,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          width: "100%",
        }}
      >
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            fontSize: "30px",
            color: "#A3020C",
          }}
        >
          Report Generation
        </h1>
      </div>
      <Select
        style={{ width: 250, marginLeft: "3%" }}
        placeholder="Report Types"
        onChange={(e) => handleSelectReportType(e)}
      >
        {selectionReportData.map((ele, index) => (
          <Select.Option key={index} value={ele.id}>
            {ele.title}
          </Select.Option>
        ))}
      </Select>
      {reportData ? (
        <>
          {reportType === 1 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "flex-start",
                  marginLeft: "3%",
                  marginTop: "3%",
                  marginRight: "4%",
                  gap: 40,
                }}
              >
                {/* <Select
                  showSearch
                  placeholder="Select Fund"
                  optionFilterProp="children"
                  onChange={(e) => handleSelectFundHouse(e)}
                  style={{
                    fontSize: "14px",
                    color: "#A3020C",
                    width: "200px",
                  }}
                >
                  {fundHouseData.map((ele, index) => (
                    <Select.Option key={index} value={ele.client_id}>
                      {ele.client_name}
                    </Select.Option>
                  ))}
                </Select> */}
                <Select
                  // disabled={disableScheme}
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={(e) => setSelectedScheme(e)}
                  style={{
                    fontSize: "14px",
                    color: "#A3020C",
                    width: "200px",
                  }}
                >
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>
                <DatePicker
                  className="w-small"
                  placeholder="Select Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={(current) => {
                    return current >= moment().endOf("day");
                  }}
                  onChange={(date) =>
                    setSelectedToDate(moment(date).format("YYYY-MM-DD"))
                  }
                />

                <Button
                  disabled={selectedScheme === "" ? true : false}
                  onClick={() => fetchDtrData()}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                >
                  Get
                </Button>
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}
                >
                  Reset
                </Button>
              </div>

              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: "block",
                      justifyContent: "center",
                      marginLeft: "3%",
                      marginTop: "3%",
                      marginRight: "3%",
                      width: "93%",
                    }}
                    columns={columnsDtr}
                    dataSource={dtrTableData}
                    bordered
                    loading={loading}
                    onChange={handleChange}
                    rowKey={(record) => record.id}
                  />
                  <div className="flex justify-center content-center mt-10 mb-10">
                    <DtrReports
                      data={dtrTableData}
                      fromDate={selectedFromDate}
                      toDate={selectedToDate}
                      fundHouse={selectedFundHouse}
                    />
                  </div>
                </div>
              ) : (
                // ) : null}
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  Please Select A Scheme and Date
                </div>
              )}
            </>
          )}
          {/* UCR REPORT GENERATION  */}
          {reportType === 2 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "flex-start",
                  marginLeft: "3%",
                  marginTop: "3%",
                  marginRight: "4%",
                  gap: 40,
                }}
              >
                {/* <Select
                  showSearch
                  placeholder="Select Fund"
                  optionFilterProp="children"
                  onChange={(e) => handleSelectFundHouse(e)}
                  style={{
                    fontSize: "14px",
                    color: "#A3020C",
                    width: "200px",
                  }}
                >
                  {fundHouseData.map((ele, index) => (
                    <Select.Option key={index} value={ele.client_id}>
                      {ele.client_name}
                    </Select.Option>
                  ))}
                </Select> */}
                <Select
                  // disabled={disableScheme}
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={(e) => setSelectedScheme(e)}
                  style={{
                    fontSize: "14px",
                    color: "#A3020C",
                    width: "200px",
                  }}
                >
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                <DatePicker
                  className="w-small"
                  placeholder="Select Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={(current) => {
                    return current >= moment().endOf("day");
                  }}
                  onChange={(date) =>
                    setSelectedToDate(moment(date).format("YYYY-MM-DD"))
                  }
                />

                <Button
                  disabled={selectedScheme === "" ? true : false}
                  onClick={() => fetchUcrData()}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                >
                  Get
                </Button>
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}
                  // disabled={!hasSelected}
                >
                  Reset
                </Button>
              </div>
              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: "block",
                      justifyContent: "center",
                      marginLeft: "3%",
                      marginTop: "3%",
                      marginRight: "3%",
                      width: "93%",
                    }}
                    columns={columnsUcr}
                    dataSource={ucrTableData}
                    bordered
                    loading={loading}
                    onChange={handleChange}
                    rowKey={(record) => record.id}
                  />

                  <div className="flex justify-center content-center mt-10 mb-10">
                    <UcrReports
                      data={ucrTableData}
                      date={selectedToDate}
                      fundHouse={selectedFundHouse}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  Please Select A Scheme and Date
                </div>
              )}
            </>
          )}

          {/* UHR REPORT GENERATION */}
          {reportType === 3 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "flex-start",
                  marginLeft: "3%",
                  marginTop: "3%",
                  marginRight: "4%",
                  gap: 40,
                }}
              >
                {/* <Select
                  showSearch
                  placeholder="Select Fund"
                  optionFilterProp="children"
                  onChange={(e) => handleSelectFundHouse(e)}
                  style={{
                    fontSize: "14px",
                    color: "#A3020C",
                    width: "200px",
                  }}
                >
                  {fundHouseData.map((ele, index) => (
                    <Select.Option key={index} value={ele.client_id}>
                      {ele.client_name}
                    </Select.Option>
                  ))}
                </Select> */}
                <Select
                  // disabled={disableScheme}
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={(e) => setSelectedScheme(e)}
                  style={{
                    fontSize: "14px",
                    color: "#A3020C",
                    width: "200px",
                  }}
                >
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                <DatePicker
                  className="w-small"
                  placeholder="Select Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={(current) => {
                    return current >= moment().endOf("day");
                  }}
                  onChange={(date) =>
                    setSelectedToDate(moment(date).format("YYYY-MM-DD"))
                  }
                />

                <Button
                  disabled={selectedScheme === "" ? true : false}
                  onClick={() => fetchUhrData()}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                >
                  Get
                </Button>
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}
                  // disabled={!hasSelected}
                >
                  Reset
                </Button>
              </div>
              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: "block",
                      justifyContent: "center",
                      marginLeft: "3%",
                      marginTop: "3%",
                      marginRight: "3%",
                      width: "93%",
                    }}
                    columns={columnsUhr}
                    dataSource={uhrTableData}
                    bordered
                    loading={loading}
                    onChange={handleChange}
                    rowKey={(record) => record.id}
                  />

                  <div className="flex justify-center content-center mt-10 mb-10">
                    <UhrReports
                      data={uhrTableData}
                      date={selectedToDate}
                      fundHouse={selectedFundHouse}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  Please Select A Scheme and Date
                </div>
              )}
            </>
          )}

          {/* Transaction Register Report Data*/}
          {reportType === 4 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "flex-start",
                  marginLeft: "3%",
                  marginTop: "3%",
                  marginRight: "4%",
                  gap: 40,
                }}
              >
                {/* <DatePicker
                  className="w-small"
                  placeholder="Select From Date"
                  format="DD/MM/YYYY"
                  selected={selectedFromDate}
                  disabledDate={(current) => {
                    return current >= moment().endOf("day");
                  }}
                  onChange={(date) =>
                    setSelectedFromDate(moment(date).format("YYYY-MM-DD"))
                  }
                /> */}

                <Select
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={(e) => setSelectedScheme(e)}
                  style={{
                    fontSize: "14px",
                    color: "#A3020C",
                    width: "200px",
                  }}
                >
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                <DatePicker
                  className="w-small"
                  placeholder="Select Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={(current) => {
                    return current >= moment().endOf("day");
                  }}
                  onChange={(date) =>
                    setSelectedToDate(moment(date).format("YYYY-MM-DD"))
                  }
                />

                <Button
                  disabled={selectedScheme === "" ? true : false}
                  onClick={() => fetchTransactionRegisterData()}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                >
                  Get
                </Button>
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}
                >
                  Reset
                </Button>
              </div>

              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: "block",
                      justifyContent: "center",
                      marginLeft: "3%",
                      marginTop: "3%",
                      marginRight: "3%",
                      width: "93%",
                    }}
                    columns={columnsTransactionRegister}
                    dataSource={transactionRegisterData}
                    bordered
                    loading={loading}
                    onChange={handleChange}
                    rowKey={(record) => record.id}
                  />
                  <div className="flex justify-center content-center mt-10 mb-10">
                    <TransactionRegisterReports
                      data={transactionRegisterData}
                      fromDate={selectedFromDate}
                      toDate={selectedToDate}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  Please Select A Scheme and Date
                </div>
              )}
            </>
          )}

          {/* NCT Report Data*/}
          {reportType === 5 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "flex-start",
                  marginLeft: "3%",
                  marginTop: "3%",
                  marginRight: "4%",
                  gap: 40,
                }}
              >
                {/* <DatePicker
                  className="w-small"
                  placeholder="Select From Date"
                  format="DD/MM/YYYY"
                  selected={selectedFromDate}
                  disabledDate={(current) => {
                    return current >= moment().endOf("day");
                  }}
                  onChange={(date) =>
                    setSelectedFromDate(moment(date).format("YYYY-MM-DD"))
                  }
                /> */}

                <Select
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={(e) => setSelectedScheme(e)}
                  style={{
                    fontSize: "14px",
                    color: "#A3020C",
                    width: "200px",
                  }}
                >
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                <DatePicker
                  className="w-small"
                  placeholder="Select Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={(current) => {
                    return current >= moment().endOf("day");
                  }}
                  onChange={(date) =>
                    setSelectedToDate(moment(date).format("YYYY-MM-DD"))
                  }
                />

                <Button
                  disabled={selectedScheme === "" ? true : false}
                  onClick={() => fetchNctData()}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                >
                  Get
                </Button>
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}
                >
                  Reset
                </Button>
              </div>

              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: "block",
                      justifyContent: "center",
                      marginLeft: "3%",
                      marginTop: "3%",
                      marginRight: "3%",
                      width: "93%",
                    }}
                    columns={columnsNct}
                    dataSource={nctTableData}
                    bordered
                    loading={loading}
                    onChange={handleChange}
                    rowKey={(record) => record.id}
                  />
                  <div className="flex justify-center content-center mt-10 mb-10">
                    <NctReports
                      data={nctTableData}
                      fromDate={selectedFromDate}
                      toDate={selectedToDate}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  Please Select A Scheme and Date
                </div>
              )}
            </>
          )}

          {/* Credit Marking Report Data*/}
          {reportType === 6 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "flex-start",
                  marginLeft: "3%",
                  marginTop: "3%",
                  marginRight: "4%",
                  gap: 40,
                }}
              >
                {/* <DatePicker
                  className="w-small"
                  placeholder="Select From Date"
                  format="DD/MM/YYYY"
                  selected={selectedFromDate}
                  disabledDate={(current) => {
                    return current >= moment().endOf("day");
                  }}
                  onChange={(date) =>
                    setSelectedFromDate(moment(date).format("YYYY-MM-DD"))
                  }
                /> */}

                <Select
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={(e) => setSelectedScheme(e)}
                  style={{
                    fontSize: "14px",
                    color: "#A3020C",
                    width: "200px",
                  }}
                >
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                <DatePicker
                  className="w-small"
                  placeholder="Select Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={(current) => {
                    return current >= moment().endOf("day");
                  }}
                  onChange={(date) =>
                    setSelectedToDate(moment(date).format("YYYY-MM-DD"))
                  }
                />

                <Button
                  disabled={selectedScheme === "" ? true : false}
                  onClick={() => fetchCreditMarkingData()}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                >
                  Get
                </Button>
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}
                >
                  Reset
                </Button>
              </div>

              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: "block",
                      justifyContent: "center",
                      marginLeft: "3%",
                      marginTop: "3%",
                      marginRight: "3%",
                      width: "93%",
                    }}
                    columns={columnsCreditMarking}
                    dataSource={creditMarkingData}
                    bordered
                    loading={loading}
                    onChange={handleChange}
                    rowKey={(record) => record.id}
                  />
                  <div className="flex justify-center content-center mt-10 mb-10">
                    <CreditMarkingReports
                      data={creditMarkingData}
                      fromDate={selectedFromDate}
                      toDate={selectedToDate}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  Please Select A Scheme and Date
                </div>
              )}
            </>
          )}

          {/* NAV Report Data*/}
          {reportType === 7 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "flex-start",
                  marginLeft: "3%",
                  marginTop: "3%",
                  marginRight: "4%",
                  gap: 40,
                }}
              >
                {/* <DatePicker
                  className="w-small"
                  placeholder="Select From Date"
                  format="DD/MM/YYYY"
                  selected={selectedFromDate}
                  disabledDate={(current) => {
                    return current >= moment().endOf("day");
                  }}
                  onChange={(date) =>
                    setSelectedFromDate(moment(date).format("YYYY-MM-DD"))
                  }
                /> */}
                <DatePicker
                  className="w-small"
                  placeholder="Select Date"
                  format="DD/MM/YYYY"
                  selected={selectedToDate}
                  disabledDate={(current) => {
                    return current >= moment().endOf("day");
                  }}
                  onChange={(date) =>
                    setSelectedToDate(moment(date).format("YYYY-MM-DD"))
                  }
                />

                <Select
                  showSearch
                  placeholder="Select Scheme"
                  optionFilterProp="children"
                  onChange={(e) => setSelectedScheme(e)}
                  style={{
                    fontSize: "14px",
                    color: "#A3020C",
                    width: "200px",
                  }}
                >
                  {schemeData.map((ele, index) => (
                    <Select.Option key={index} value={ele.fund_ID}>
                      {ele.fund_Description}
                    </Select.Option>
                  ))}
                </Select>

                <Button
                  disabled={selectedScheme === "" ? true : false}
                  onClick={() => fetchNAVReportData()}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                >
                  Get
                </Button>
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "140px",
                    height: "33px",
                    backgroundColor: "#A3020C",
                    color: "white",
                    borderRadius: 5,
                  }}
                  onClick={() => window.location.reload(false)}
                >
                  Reset
                </Button>
              </div>

              {showTable ? (
                <div>
                  <Table
                    style={{
                      display: "block",
                      justifyContent: "center",
                      marginLeft: "3%",
                      marginTop: "3%",
                      marginRight: "3%",
                      width: "93%",
                    }}
                    columns={columnsNAVReport}
                    dataSource={navReportData}
                    bordered
                    loading={loading}
                    onChange={handleChange}
                    rowKey={(record) => record.id}
                  />
                  <div className="flex justify-center content-center mt-10 mb-10">
                    <NavReports
                      data={navReportData}
                      fromDate={selectedFromDate}
                      toDate={selectedToDate}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  Please Select A Scheme and Date
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div
          className="flex justify-center content-center mt-10 mb-10 bg-gray-100 border border-gray-400 text-black-700 px-4 py-3 rounded relative"
          role="alert"
        >
          Please Select A Report Type
        </div>
      )}
    </div>
  );
};

export default ReportGeneration;
