import React, { useState, useEffect } from "react";
import { Button, Spin, Modal } from "antd";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import moment from "moment/moment";
import { useStateContext } from "../../contexts/ContextProvider";
import axios from "axios";

const UhrReports = ({ data, date, fundHouse }) => {
  const { apiBaseUrl, user_id } = useStateContext();
  const [exportButton, setExportButton] = useState(true);
  const [workflowData, setWorkflowData] = useState([]);
  const [downloading, setDownloading] = useState(false);

  var workflowdate = moment().utcOffset("+05:30").format();
  const [pageLoadingTimeStamp, setPageLoadingTimeStamp] =
    useState(workflowdate);

  useEffect(() => {
    // set the export button to false, if data is there it will change to true or else it will be false
    setExportButton(false);
    if (data.length === 0) {
      setExportButton(true);
    }
    //console.log(date)
  }, [data]);

  useEffect(() => {
    getWorkflowAPI();
  }, []);

  const getWorkflowAPI = () => {
    // console.log(`${apiBaseUrl}/VWorkflow/getworkflowdata`, "workflowData");
    fetch(`${apiBaseUrl}/VWorkflow/getworkflowdata?wfseqcode=URDTR`)
      .then((repsonse) => repsonse.json())
      .then((json) => {
        //  console.log(json);
        setWorkflowData(json.responseData);
      });
  };

  const createDownLoadUhrData = () => {
    // Downloading the UH Report
    insertWorkflowAPI(data);
    setDownloading(true);
    if (downloading === false) {
      handleUhrExport().then((url) => {
        // Calling the handleUHRExport Format and converting it into Excel for downloading
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", url);
        downloadAnchorNode.setAttribute(
          "download",
          `UnitHoldingReport_${date}.xls`
        );
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      });
    } else {
      Modal.info({
        title: "Please wait while the UHR is downloading",
      });
    }
  };

  const insertWorkflowAPI = (data) => {
    setDownloading(true);
    var submitTimeStamp = moment().utcOffset("+05:30").format();
    let pageLoadingTimeStampInSeconds = moment(pageLoadingTimeStamp).seconds();
    let submitTimeStampInSeconds = moment(submitTimeStamp).seconds();
    let actualAHT_Seconds = Math.abs(
      submitTimeStampInSeconds - pageLoadingTimeStampInSeconds
    );
    for (let i = 0; i < data.length; i++) {
      // if(data[i].transaction_type_id === 2 || data[i].transaction_type_id === 3){
      var apiInsertWorkFlowData = {
        worfklow_item_create_ts: pageLoadingTimeStamp,
        workflow_item_reference_value: user_id,
        isactive: true,
        workflow_id: workflowData[0].workflow_id,
        workflow_step_id: workflowData[0].worflow_step_id,
        system_feature_operation_id: 1,
        assigned_by_user_id: user_id,
        assigned_to_user_id: user_id,
        completed_by_user_id: user_id,
        assigned_ts: pageLoadingTimeStamp,
        completed_ts: pageLoadingTimeStamp,
        updation_date: pageLoadingTimeStamp,
        creation_by: user_id,
        creation_date: pageLoadingTimeStamp,
        workflow_step_json_data: "UHR report has been generated",
        workflow_status: "completed",
        actual_aht_seconds: actualAHT_Seconds,
        worflow_step_sequence_number:
          workflowData[0].worflow_step_sequence_number,
        application_num: data[i].applicationnumber,
        client_id: fundHouse,
      };
      axios
        .post(`${apiBaseUrl}/VWorkFlow/insertWorkflow`, apiInsertWorkFlowData, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          let temp = response.data.responseData;
          console.log("insertWorkflowAPI of UHR response........", temp);
          setDownloading(false);
        })
        .catch((error) => {
          console.log(error);
          setDownloading(false);
        });
      // }
    }
  };

  const workbook2blob = (workbook) => {
    //Converting data into Excel
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    //Sorting of data in Excel
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleUhrExport = () => {
    // UHR Excel Format
    const title = [
      {
        A: `Unit Holding Report of  "${
          data[0].clientname
        }"  as on Date ${moment(date).format("DD-MM-YYYY")}`,
      },
      {},
    ];

    let table = [
      {
        A: "Sno",
        B: "Scheme",
        C: "Class",
        D: "Account Number",
        E: "Investor Name",
        F: "Address 1",
        G: "Address 2",
        H: "City",
        I: "State",
        J: "Pincode",
        K: "Nri Address 1",
        L: "Nri Address 2",
        M: "Nri Country",
        N: "Nri State",
        O: "Nri City",
        P: "Nri ZipCode",
        Q: "Category Name",
        R: "Residential Status",
        S: "Committment Amount",
        T: "Contribution Amount",
        U: "AUM",
        V: "Balance Units",
        W: "NAV",
        X: "NAV Date",
        Y: "Pledge Units",
        Z: "Pledge In Favour Of",
        AA: "Distribution Code",
        AB: "Distribution Name",
        AC: "RM Code",
        AD: "RM Name",
        AE: "AMC RM Name",
        AF: "Bank Name",
        AG: "Bank Account Number",
        AH: "Bank Micr Code",
        AI: "Bank Ifsc Code",
        AJ: "Bank Account Type",
        AK: "Bank Address",
        AL: "Bank Pincode",
        AM: "Primary Holder Moblie Number",
        AN: "Primary Holder Email Id",
        AO: "Primary Holder Pan Number",
        AP: "Primary Holder DOB",
        AQ: "Mode Of Holding",
        AR: "First Holder Name",
        AS: "First Holder Pan",
        AT: "First Holder RelationShip",
        AU: "Second Holder Name",
        AV: "Second Holder Pan",
        AW: "Second Holder RelationShip",
        AX: "Alternate Email Id",
        AY: "First Nominee Number",
        AZ: "Second Nominee Name",
        BA: "Third Nominee Number",
        BB: "Guardian Name",
        BC: "Guardian Pan",
        BD: "FATCA",
        BE: "POA Holder Name",
        BF: "POA Holder Address",
      },
    ];

    let Total = [
      {
        A: "", // this will be for total and it will be empty
      },
    ];

    //Pushing data to tables
    for (let i = 0; i < data.length; i++) {
      table.push({
        A: data[i].sno,
        B: data[i].fund_name,
        C: data[i].class_name,
        D: `${" " + data[i].account_number}`,
        E: data[i].investorname,
        F: data[i].address_line1,
        G: data[i].address_line2,
        H: data[i].city_name,
        I: data[i].state_name,
        J: data[i].pin_code,
        K: data[i].nri_address_line1,
        L: data[i].nri_address_line2,
        M: data[i].nri_country,
        N: data[i].nri_state,
        O: data[i].nr_city,
        P: data[i].nri_zipcode,
        Q: data[i].category_name,
        R: data[i].residential_status,
        S: data[i].committment_amount,
        T: data[i].contributionamount,
        U: data[i].aum,
        V: data[i].balance_units,
        W: data[i].nav,
        X: data[i].nav_date,
        Y: data[i].pledgedunits,
        Z: data[i].pledgein_favourof,
        AA: data[i].distributorcode,
        AB: data[i].distributorname,
        AC: data[i].rmcode,
        AD: data[i].rmname,
        AE: data[i].amc_rmname,
        AF: data[i].bankname,
        AG: `${" " + data[i].bankaccount_number}`,
        AH: data[i].bankmicr_code,
        AI: data[i].bankifsc_code,
        AJ: data[i].bankaccount_type,
        AK: data[i].bank_address,
        AL: data[i].bank_pincode,
        AM: data[i].primary_holder_mobile_num,
        AN: data[i].primary_holder_emailid,
        AO: data[i].primary_holder_pan_num,
        AP: data[i].primary_holder_dob,
        AQ: data[i].modeof_holding,
        AR: data[i].first_holder_name,
        AS: data[i].first_holder_pan,
        AT: data[i].first_holder_relationship,
        AU: data[i].second_holder_name,
        AV: data[i].second_holder_pan,
        AW: data[i].second_holder_relationship,
        AX: data[i].alternate_emailid,
        AY: data[i].first_nominee_name,
        AZ: data[i].second_nominee_name,
        BA: data[i].third_nominee_name,
        BB: data[i].gaurdian_name,
        BC: data[i].gaurdian_pan,
        BD: data[i].fatca,
        BE: data[i].poa_holdername,
        BF: data[i].poa_holderaddress,
      });
    }

    const totalSum = data.reduce(
      (acc, row) => acc + parseFloat(row.balance_units),
      0
    );
    // console.log(totalSum);

    Total.push({
      A: "Total Transaction",
      B: data.length,
      T: "Total Balance Units",
      U: totalSum,
    });

    table = [{ A: "" }]
      .concat([""])
      .concat([""])
      .concat(table)
      .concat([""])
      .concat([""])
      .concat(Total);

    const finalData = [...title, ...table];

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "Unit Holding Report");

    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "Sno" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A1",
      titleRange: "A1:C2",
      tbodyRange: `A2:CZ${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:BF${headerIndexes[0] + 1}`
          : null,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    // styling the excel sheet
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",
        });

        sheet.column("A").width(25);
        sheet.column("B").width(25);
        sheet.column("C").width(30);
        sheet.column("D").width(35);
        sheet.column("E").width(35);
        sheet.column("F").width(35);
        sheet.column("G").width(25);
        sheet.column("H").width(25);
        sheet.column("I").width(25);
        sheet.column("J").width(25);
        sheet.column("K").width(25);
        sheet.column("L").width(25);
        sheet.column("M").width(25);
        sheet.column("N").width(25);
        sheet.column("O").width(25);
        sheet.column("P").width(25);
        sheet.column("Q").width(25);
        sheet.column("R").width(25);
        sheet.column("S").width(35);
        sheet.column("T").width(25);
        sheet.column("U").width(35);
        sheet.column("V").width(35);
        sheet.column("W").width(25);
        sheet.column("X").width(25);
        sheet.column("Y").width(25);
        sheet.column("Z").width(25);
        sheet.column("AA").width(25);
        sheet.column("AB").width(25);
        sheet.column("AC").width(25);
        sheet.column("AD").width(25);
        sheet.column("AE").width(35);
        sheet.column("AF").width(25);
        sheet.column("AG").width(25);
        sheet.column("AH").width(25);
        sheet.column("AI").width(25);
        sheet.column("AJ").width(25);
        sheet.column("AK").width(35);
        sheet.column("AL").width(35);
        sheet.column("AM").width(40);
        sheet.column("AN").width(35);
        sheet.column("AO").width(25);
        sheet.column("AP").width(25);
        sheet.column("AQ").width(25);
        sheet.column("AR").width(35);
        sheet.column("AS").width(25);
        sheet.column("AT").width(25);
        sheet.column("AU").width(35);
        sheet.column("AV").width(40);
        sheet.column("AW").width(40);
        sheet.column("AX").width(25);
        sheet.column("AY").width(25);
        sheet.column("AZ").width(25);
        sheet.column("BA").width(25);
        sheet.column("BB").width(25);
        sheet.column("BC").width(30);
        sheet.column("BD").width(30);
        sheet.column("BE").width(30);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
          border: true,
        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: "center",
          });
        }

        sheet.range(dataInfo.theadRange).style({
          bold: true,
          horizontalAlignment: "center",
          border: true,
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <div>
      {downloading ? (
        <Spin size="medium" />
      ) : (
        <Button
          onClick={() => {
            createDownLoadUhrData();
          }}
          disabled={exportButton}
          loading={downloading}
          style={{
            width: "140px",
            height: "33px",
            backgroundColor: "#A3020C",
            color: "white",
            borderRadius: 5,
          }}
        >
          Export as Excel
        </Button>
      )}
    </div>
  );
};

export default UhrReports;
