import React, {useState, useEffect} from 'react';
import {Table} from 'antd';
import moment from 'moment';
const ServiceRequestDetails = data => {
  const nctData = data?.data;
  const [filteredNCTInfo, setFilteredNCTInfo] = useState({});
  const [nctTableData, setNctTableData] = useState([]);
  useEffect(() => {
    nctData?.length !== 0 &&
      nctData?.forEach((ele, index) => {
        ele.sno = index + 1;
        ele.nct_transaction_date = moment(ele.nct_transaction_date)
          .format('DD/MM/YYYY')
          .toString();
        ele.nct_status =
          ele.nct_status === 'Open'
            ? 'Pending'
            : ele.nct_status === 'Closed'
            ? 'Success'
            : ele.nct_status === 'Resolved'
            ? 'Resolved'
            : 'Null';

        ele.nct_old_value =
          ele.nct_category_id === 1 ? null : ele.nct_subject_id === 154 ||
            ele.nct_subject_id === 155 ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === '1'
                ? 'Yes'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '2'
                ? 'No'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ''
                ? 'Not Available'
                : 'Not Applicable'}
            </>
          ) : ele.nct_subject_id === 28 ||
            ele.nct_subject_id === 48 ||
            ele.nct_subject_id === 64 ||
            ele.nct_subject_id === 65 ||
            ele.nct_subject_id === 49 ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === '1'
                ? 'Male'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '2'
                ? 'Female'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ''
                ? 'Not Available'
                : 'Others'}
            </>
          ) : ele.nct_subject_id === 115 || ele.nct_subject_id === 116 ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] === '1'
                ? 'Son'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '2'
                ? 'Father'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '3'
                ? 'Mother'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '4'
                ? 'Wife'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '5'
                ? 'Husband'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '6'
                ? 'Court Appointed'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === '7'
                ? 'Daughter'
                : Object.values(JSON.parse(ele.nct_old_value))[0] === ''
                ? 'Not Available'
                : 'Others'}
            </>
          ) : ele.nct_subject_id === 152 || ele.nct_subject_id === 153 ? (
            <>
              {Object.values(JSON.parse(ele.nct_old_value))[0] == '1'
                ? 'Private Sector Service'
                : Object.values(JSON.parse(ele.nct_old_value))[0] == '2'
                ? 'Public Sector'
                : Object.values(JSON.parse(ele.nct_old_value))[0] == '3'
                ? 'Government Service'
                : Object.values(JSON.parse(ele.nct_old_value))[0] == '4'
                ? 'Housewife'
                : Object.values(JSON.parse(ele.nct_old_value))[0] == '5'
                ? 'Professional'
                : Object.values(JSON.parse(ele.nct_old_value))[0] == '6'
                ? 'Defence'
                : Object.values(JSON.parse(ele.nct_old_value))[0] == ''
                ? 'N/A'
                : 'Others'}
            </>
          ) : Object.values(JSON.parse(ele.nct_old_value))[0] === '' ? (
            <>Not Available</>
          ) : (
            <>{Object.values(JSON.parse(ele.nct_old_value))[0]}</>
          );

        ele.nct_new_value =
          ele.nct_category_id === 1 ? null : ele.nct_subject_id === 154 ||
            ele.nct_subject_id === 155 ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === '1'
                ? 'Yes'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '2'
                ? 'No'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ''
                ? 'Not Available'
                : 'Not Applicable'}
            </>
          ) : ele.nct_subject_id === 28 ||
            ele.nct_subject_id === 48 ||
            ele.nct_subject_id === 64 ||
            ele.nct_subject_id === 65 ||
            ele.nct_subject_id === 49 ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === '1'
                ? 'Male'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '2'
                ? 'Female'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ''
                ? 'Not Available'
                : 'Others'}
            </>
          ) : ele.nct_subject_id == '115' || ele.nct_subject_id == '116' ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] === '1'
                ? 'Son'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '2'
                ? 'Father'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '3'
                ? 'Mother'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '4'
                ? 'Wife'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '5'
                ? 'Husband'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '6'
                ? 'Court Appointed'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === '7'
                ? 'Daughter'
                : Object.values(JSON.parse(ele.nct_new_value))[0] === ''
                ? 'Not Available'
                : 'Others'}
            </>
          ) : ele.nct_subject_id == '152' || ele.nct_subject_id == '153' ? (
            <>
              {Object.values(JSON.parse(ele.nct_new_value))[0] == '1'
                ? 'Private Sector Service'
                : Object.values(JSON.parse(ele.nct_new_value))[0] == '2'
                ? 'Public Sector'
                : Object.values(JSON.parse(ele.nct_new_value))[0] == '3'
                ? 'Government Service'
                : Object.values(JSON.parse(ele.nct_new_value))[0] == '4'
                ? 'Housewife'
                : Object.values(JSON.parse(ele.nct_new_value))[0] == '5'
                ? 'Professional'
                : Object.values(JSON.parse(ele.nct_new_value))[0] == '6'
                ? 'Defence'
                : Object.values(JSON.parse(ele.nct_new_value))[0] == ''
                ? 'N/A'
                : 'Others'}
            </>
          ) : Object.values(JSON.parse(ele.nct_new_value))[0] == '' ? (
            <>Not Available</>
          ) : (
            <>{Object.values(JSON.parse(ele.nct_new_value))[0]}</>
          );
      });
    setNctTableData(nctData);
  }, [nctData]);
  const filterNCTData = nctData =>
    nctData?.map(item => ({
      key: item,
      value: item,
      text: item,
    }));
  const handleNCTChange = (_, filters) => {
    setFilteredNCTInfo(filters);
  };
  const nctColumns = [
    {
      title: 'S. No',
      dataIndex: 'sno',
    },
    {
      title: (
        <>
          Request
          <br />
          Registration
          <br />
          Date
        </>
      ),
      dataIndex: 'nct_transaction_date',
    },
    {
      title: (
        <>
          Ticket
          <br />
          Type
        </>
      ),
      dataIndex: 'nct_category_description',
    },
    {
      title: (
        <>
          Service
          <br />
          Request
        </>
      ),
      dataIndex: 'nct_subject_description',
    },
    {
      title: (
        <>
          Change
          <br />
          Requested
        </>
      ),
      dataIndex: 'nct_new_value',
      render: (text, record) => {
        if (record.nct_old_value) {
          return (
            <>
              <span>
                Old Value: <span>{record.nct_old_value}</span>
              </span>
              <br />
              <span>
                New Value: <span>{text}</span>
              </span>
            </>
          );
        } else {
          return <span>{text}</span>;
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'nct_status',
      filters: filterNCTData(
        nctData
          ?.map(item => item.nct_status)

          ?.filter((value, index, self) => self.indexOf(value) === index),
      ),
      filteredValue: filteredNCTInfo.nct_status || null,
      onFilter: (value, record) => record.nct_status.includes(value),
    },
  ];
  return (
    <>
      <h2>
        <u>Service Request Details</u>
      </h2>
      <Table
        style={{
          display: 'block',
          justifyContent: 'center',
          marginTop: '3%',
          width: '100%',
        }}
        columns={nctColumns}
        dataSource={nctTableData}
        onChange={handleNCTChange}
        pagination={{
          pageSize: 10,
        }}
        bordered
      />
    </>
  );
};
export default ServiceRequestDetails;
