import React from "react";
import { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { Button, Modal, Checkbox } from "antd";
import axios from "axios";

const DownloadSOA = ({ clientID, fundID, accountNumber }) => {
  const folioNumber = accountNumber;
  const { apiBaseUrl } = useStateContext();
  const [base64String, setBase64String] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [SOALoaded, setSOALoaded] = useState(false);
  const [dataResponse, setDataResponse] = useState("");
  const [soaNotAvailable, setSoaNotAvailable] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const onChange = (option) => {
    setSelectedOption(option);
  };

  const handleConvert = () => {
    const base64 = base64String; // Your base64 string
    const type = "application/pdf";
    const sliceSize = 512;
    const byteCharacters = atob(base64);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: type });
    const fileUrl = URL.createObjectURL(blob);
    setFileUrl(fileUrl);
    window.open(fileUrl);
  };

  const onClickSOA = () => {
    if (accountNumber) {
      let smartStatementURL = `https://vlive.d1n3ll0fsz0uqf.amplifyapp.com/?accNum=${folioNumber}`;
      window.open(smartStatementURL);
    } else {
      console.log("Account number is undefined");
    }
  };

  const fetchSOA = () => {
    if (fundID && clientID) {
      axios
        .get(
          `${apiBaseUrl}/VReports/DownloadSOAPDFCAT2/${clientID}/${fundID}/${accountNumber}?flagforPortfolio=${selectedOption}`
        )
        .then((response) => {
          setDataResponse(response.data.code);
          setBase64String(response.data.responseData);
        })
        .catch((error) => {
          setSoaNotAvailable(true);
        });
    }
    setTimeout(() => {
      setSOALoaded(true);
    }, 5000);
  };

  useEffect(() => {
    fetchSOA();
  }, [fundID, clientID, accountNumber, apiBaseUrl]);

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "2%" }}>
      <div style={{ width: "14%" }}>
        <h2>
          <u>Personal Details</u>
        </h2>
      </div>
      <div>
        {soaNotAvailable ? (
          <p style={{ fontWeight: "bold" }}>
            SOA can't be Generated until Transaction Endorsement
          </p>
        ) : (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <Button key="submit" type="primary" onClick={showModal}>
                Statment of Account
              </Button>
              <Modal
                title="Statement of Account"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <p>Display Portfolio Holder ?</p>
                <Checkbox
                  checked={selectedOption === "Y"}
                  onChange={() => onChange("Y")}
                >
                  Yes
                </Checkbox>
                <Checkbox
                  checked={selectedOption === "N"}
                  onChange={() => onChange("N")}
                >
                  No
                </Checkbox>
                <p>
                  Click on the button below to display the Statement of Account
                </p>
                <Button
                  key="submit"
                  type="primary"
                  loading={!SOALoaded}
                  onClick={handleConvert}
                >
                  Display SOA
                </Button>
                <Button
                  key="submit"
                  style={{ marginLeft: "2%" }}
                  type="primary"
                  loading={!SOALoaded}
                  onClick={onClickSOA}
                >
                  Display Smart Account Statement
                </Button>
              </Modal>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default DownloadSOA;
