import React from 'react';
const SecondHolderDetails = props => {
  const {secondHolderData} = props;
  const InfoItem = ({label, value}) => (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <p style={{width: '35%'}}>{label} :</p>
      <p style={{width: '45%'}}>{value}</p>
    </div>
  );

  const DateInfoItem = ({label, date}) => (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <p style={{width: '35%'}}>{label} :</p>
      <p style={{width: '40%'}}>
        {date ? new Date(date).toLocaleDateString('en-GB') : 'Not Available'}
      </p>
    </div>
  );

  return (
    <>
      {/* Joint Holder Details */}
      {secondHolderData?.length > 0 ? (
        <>
          <h2>
            <u>Joint Holder Details</u>
          </h2>
          <div style={{fontWeight: 'bold'}}>First Joint Holder</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '2%',
                width: '50%',
              }}>
              <InfoItem
                label="Name"
                value={`${secondHolderData[0]?.account_Holder?.first_name} ${secondHolderData[0]?.account_Holder?.last_name}`}
              />
              <InfoItem
                label="Gender"
                value={
                  secondHolderData[0]?.account_Holder?.gender_id === 1
                    ? 'Male'
                    : secondHolderData[0]?.account_Holder?.gender_id === 2
                    ? 'Female'
                    : 'Others'
                }
              />
              <InfoItem
                label="Relation"
                value={
                  secondHolderData[0]?.holder_relationship_type_id === 1
                    ? 'Son'
                    : secondHolderData[0]?.holder_relationship_type_id === 2
                    ? 'Father'
                    : secondHolderData[0]?.holder_relationship_type_id === 3
                    ? 'Mother'
                    : secondHolderData[0]?.holder_relationship_type_id === 4
                    ? 'Wife'
                    : secondHolderData[0]?.holder_relationship_type_id === 5
                    ? 'Husband'
                    : secondHolderData[0]?.holder_relationship_type_id === 6
                    ? 'Court Appointed'
                    : secondHolderData[0]?.holder_relationship_type_id === 7
                    ? 'Daughter'
                    : secondHolderData[0]?.holder_relationship_type_id === null
                    ? 'Not Available'
                    : 'Others'
                }
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '2%',
                width: '50%',
              }}>
              <DateInfoItem
                label="Date of birth"
                date={secondHolderData[0]?.account_Holder?.dob}
              />

              <InfoItem
                label="PAN Number"
                value={
                  secondHolderData[0]?.prooflist &&
                  secondHolderData[0]?.prooflist[0]?.proof_value ? (
                    <span>
                      {secondHolderData[0]?.prooflist[0]?.proof_value}
                    </span>
                  ) : (
                    <span>Not Available</span>
                  )
                }
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SecondHolderDetails;
