import React, {useState, useEffect} from 'react';
import {Table} from 'antd';
import moment from 'moment';
import {addIndianCommas} from '../../../components/utils/helperFunction';

const DrawdownDetails = data => {
  const drawdownData = data?.data;
  const [drawdownTableData, setDrawdownTableData] = useState([]);
  const filterDrawdownData = drawdownData =>
    drawdownData.map(item => ({
      key: item,
      value: item,
      text: item,
    }));

  const handleDrawdownChange = (_, filters) => {
    setFilteredDrawdownInfo(filters);
  };
  useEffect(() => {
    const updatedDrawdownData = drawdownData?.map(ele => ({
      ...ele,
      drawdown_start_date: ele.drawdown_start_date
        ? moment(ele.drawdown_start_date).format('DD/MM/YYYY').toString()
        : 'Not Available',
      drawdown_end_date: ele.drawdown_end_date
        ? moment(ele.drawdown_end_date).format('DD/MM/YYYY').toString()
        : 'Not Available',
      endorsement_date: ele.endorsement_date
        ? moment(ele.endorsement_date).format('DD/MM/YYYY').toString()
        : 'Not Available',
      transaction_status:
        ele.transaction_status !== 'P' ? 'Approved' : 'Pending',
      transaction_amount: addIndianCommas(ele.transaction_amount),
    }));
    setDrawdownTableData(updatedDrawdownData);
  }, []);

  const [filteredDrawdownInfo, setFilteredDrawdownInfo] = useState({});
  const drawdownColumns = [
    {
      title: 'S. No',
      dataIndex: 'drawdown_no',
      fixed: 'left',
    },
    {
      title: 'Client',
      dataIndex: 'client_name',
      fixed: 'left',
      filters: filterDrawdownData(
        drawdownTableData
          ?.map(item => item.client_name)

          .filter((value, index, self) => self.indexOf(value) === index),
      ),
      filteredValue: filteredDrawdownInfo.client_name || null,
      onFilter: (value, record) => record.client_name.includes(value),
    },
    {
      title: 'Scheme',
      dataIndex: 'fund_Description',
      fixed: 'left',
      filters: filterDrawdownData(
        drawdownTableData
          ?.map(item => item.fund_Description)

          .filter((value, index, self) => self.indexOf(value) === index),
      ),
      filteredValue: filteredDrawdownInfo.fund_Description || null,
      onFilter: (value, record) => record.fund_Description.includes(value),
    },
    {
      title: 'Start Date',
      dataIndex: 'drawdown_start_date',
    },
    {
      title: 'End Date',
      dataIndex: 'drawdown_end_date',
    },
    {
      title: 'Amount',
      dataIndex: 'transaction_amount',
      align: 'right',
    },
    {
      title: 'Status',
      dataIndex: 'transaction_status',
      fixed: 'right',
      filters: filterDrawdownData(
        drawdownTableData
          ?.map(item => item.transaction_status)

          .filter((value, index, self) => self.indexOf(value) === index),
      ),
      filteredValue: filteredDrawdownInfo.transaction_status || null,
      onFilter: (value, record) => record.transaction_status.includes(value),
    },
  ];

  return (
    <div>
      <h2>
        <u>Drawdown Details</u>
      </h2>
      <Table
        style={{
          display: 'block',
          justifyContent: 'center',
          marginTop: '3%',
          width: '100%',
        }}
        onChange={handleDrawdownChange}
        columns={drawdownColumns}
        dataSource={drawdownTableData}
        pagination={{
          pageSize: 10,
        }}
        bordered
      />
    </div>
  );
};

export default DrawdownDetails;
