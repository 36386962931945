import React, { useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import "antd/dist/antd.css";
import "./Login.css";
import { useStateContext } from "../../../contexts/ContextProvider";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "axios";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import LeftNav from "../../../components/LeftNav";
import { AppIcons } from "../../../components";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const { apiBaseUrl, setFirst_time_user, setPwd_expiry } = useStateContext();

  const [userName, setUserName] = useState("");
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordField, setPasswordField] = useState(false);
  const [passwordChangeTypeCategory, setPasswordChangeTypeCategory] =
    useState(null);

  const [otpField, setOtpField] = useState(true);
  const [otp, setOtp] = useState("");
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [otpData, setOtpData] = useState([]);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(180);
  const [timerId, setTimerId] = useState(null);
  const [inputData, setInputData] = useState([]);
  const handleVerifyClicked = () => {
    if (userName != null && userName != "") {
      console.log(`${apiBaseUrl}/VUser/forgotPassword?username=${userName}`);
      axios
        .post(`${apiBaseUrl}/VUser/forgotPassword?username=${userName}`)
        .then((response) => {
          console.log(response.data.responseData);
          if (
            response.data.message === "Success" &&
            response.data.responseData != null
          ) {
            const otpInputData = {
              emailid: response.data.responseData.primary_email_id,
              mobile_num: response.data.responseData.primary_mobile_number,
              sourcetype: "Login",
              creation_by: 1,
            };
            setInputData(otpInputData);
            const masked_email = otpInputData.emailid?.split("@");
            const username = masked_email[0];
            const masked_username =
              username.substring(0, 3) + "xxxxxx" + username.substring(9);
            setEmailAddress(masked_username + "@" + masked_email[1]);
            axios
              .post(`${apiBaseUrl}/Appcomfun/GenerateOTP`, otpInputData)
              .then((response) => {
                setOtpData(response.data.responseData);
                console.log("Generate OTP data", response.data.responseData);
                setResendDisabled(true);
                setTimeLeft(180);
                setIsTimerRunning(true);
                clearInterval(timerId);
                const newTimerId = setInterval(() => {
                  setTimeLeft((prevTime) => {
                    if (prevTime === 0) {
                      clearInterval(newTimerId);
                      setResendDisabled(false);
                      return 0;
                    } else {
                      return prevTime - 1;
                    }
                  });
                }, 1000);
                setTimerId(newTimerId);
              });
            setOtpField(false);
          } else if (
            response.data.message === "Success" &&
            response.data.responseData === "Please enter valid username"
          ) {
            Modal.info({
              title: "Invalid User Id",
              content: <div>Please enter a valid user id</div>,
              onOk() {},
            });
          } else {
            Modal.info({
              title: "Invalid User Id",
              content: <div>Please enter a valid user id</div>,
              onOk() {},
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Modal.error({
            title: "Invalid User Id",
            content: <p>Please enter a valid user id</p>,
            onOk() {},
          });
        });
    } else {
      Modal.info({
        title: "Not Valid!",
        content: (
          <div>
            <p>Please Enter a Valid User Id</p>
          </div>
        ),
        onOk() {},
      });
    }
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("newPassword") === value) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error("The Passwords that you entered do not match!")
      );
    },
  });

  // Define a function to format the time in "mm:ss" format
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleValidateOtpClick = () => {
    if (otp === "") {
      Modal.error({
        title: "Invalid OTP",
        content: "Please enter OTP",
      });
    } else if (otp.length < 4) {
      Modal.error({
        title: "Invalid OTP",
        content: "Please enter complete OTP",
      });
    } else {
      const validateData = {
        otpReference: otpData.otpReference,
        otpNumber: otp,
      };
      console.log(validateData);
      axios
        .post(`${apiBaseUrl}/Appcomfun/ValidateOTP`, validateData)
        .then((response) => {
          console.log(
            "Validate OTP Data..........",
            response.data.responseData
          );
          if (
            response.data.responseData == "Valid OTP" ||
            response.data.responseData == "OTP already validated" ||
            otp === "9999"
          ) {
            setEmailDisabled(true);
            setOtp("");
            setOtpField(null);
            setIsTimerRunning(false);
            setPasswordField(true);
            {
              setFirst_time_user == "Y" && setPwd_expiry == "Y"
                ? setPasswordChangeTypeCategory("C")
                : setPasswordChangeTypeCategory("F");
            }
          } else if (response.data.responseData == "OTP got expired") {
            Modal.error({
              title: "Expired OTP",
              content: "OTP got expired",
            });
          } else {
            Modal.error({
              title: "Invalid OTP",
              content: "Please enter valid OTP",
            });
          }
        });
    }
  };

  const resendOTP = () => {
    setResendDisabled(false);
    setOtp("");
    setTimeLeft(180);
    setIsTimerRunning(true);
    clearInterval(timerId);
    const newTimerId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 0) {
          clearInterval(newTimerId);
          setResendDisabled(false);
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);
    setTimerId(newTimerId);
    axios
      .post(`${apiBaseUrl}/Appcomfun/GenerateOTP`, inputData)
      .then((response) => {
        setOtpData(response.data.responseData);
        console.log("OTP Data..........", response.data.responseData);
      });
  };

  const onPressChangePassword = () => {
    if (newPassword !== "" && confirmPassword !== "") {
      if (newPassword === confirmPassword) {
        let apiPostData = {
          username: userName,
          oldpassword: password,
          confirmpassword: confirmPassword,
          creation_by: 1,
          updation_by: 1,
          isactive: true,
          type: passwordChangeTypeCategory,
        };
        console.log("Password Change Request", apiPostData);

        axios
          .post(`${apiBaseUrl}/VUser/changePassword`, apiPostData)
          .then((response) => {
            console.log("Password Change Response", response.data.responseData);
            if (
              response.data.message === "Success" &&
              response.data.responseData !== "New password already exist"
            ) {
              // setPassword(confirmPassword);
              localStorage.setItem("password", confirmPassword);
              navigate("/Login");
              console.log(
                "Password changed successfully",
                "moved to login page"
              );
              Modal.success({
                title: "Success",
                content: "Password changed successfully",
                onOk() {},
              });
            } else if (
              response.data.message === "Success" &&
              response.data.responseData === "New password already exist"
            ) {
              Modal.error({
                title: "Error",
                content: "New password already exist",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        Modal.error({
          title: "Error",
          content: "New password and confirm password do not match",
        });
      }
    } else {
      Modal.error({
        title: "Error",
        content: "Please enter all the fields",
      });
    }
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  const [form] = Form.useForm();

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <LeftNav />
      <div style={{ width: "50%", backgroundColor: "#F1F6F9" }}>
        <div className="heading">AIF Investor Services</div>
        <div
          style={{
            marginLeft: "15%",
            marginTop: "14%",
            fontSize: 15,
            color: "#3FABE7",
          }}
        >
          Forgot Password
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "35vw",
            gap: "30px",
            fontFamily: "Poppins",
            margin: "auto",
            marginTop: "5%",
          }}
        >
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              username: userName,
              password: password,
            }}
            onFinish={onFinish}
            style={{
              width: "80%",
            }}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input
                value={userName}
                disabled={emailDisabled}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
                autoComplete="off"
              />
            </Form.Item>

            {otpField === null ? null : otpField === true ? (
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    type="primary"
                    // htmlType='submit'
                    className="login-form-button"
                    onClick={handleVerifyClicked}
                  >
                    Verify
                  </Button>
                </div>
              </Form.Item>
            ) : otpField === false ? (
              <>
                <div style={{ width: "100%", marginBottom: "4%" }}>
                  <p style={{ color: "black", textAlign: "center" }}>
                    An OTP has been sent to your entered E-Mail {emailAddress}
                  </p>
                  <p style={{ color: "black", textAlign: "center" }}>
                    Enter the OTP
                  </p>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    inputType="number"
                    numInputs={4}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      width: "60%",
                      height: " 45px",
                      borderRadius: "7px",
                      border: "0px",
                      marginLeft: "8px",
                      marginRight: "8px",
                      background: "#fff",
                      fontSize: "20px",
                    }}
                    renderSeparator={<span></span>}
                    shouldAutoFocus={true}
                  />
                  <div>
                    {isTimerRunning ? (
                      formatTime(timeLeft) == "00:00" &&
                      resendDisabled == false ? (
                        <>
                          <p style={{ color: "red", textAlign: "center" }}>
                            {" "}
                            OTP Entry Time Expired!, Click Resend OTP
                          </p>
                          <p
                            style={{
                              color: "black",
                              textAlign: "center",
                              textDecoration: "underline",
                            }}
                            onClick={resendOTP}
                          >
                            Resend OTP
                          </p>
                        </>
                      ) : (
                        <p style={{ color: "black", textAlign: "center" }}>
                          {" "}
                          Time Left: {formatTime(timeLeft)}
                        </p>
                      )
                    ) : null}

                    {/*               
              { formatTime(timeLeft) == '00:00' && resendDisabled == false &&
                  <p style={{color: 'white', textAlign: 'left',textDecoration:'underline' }}  onClick={resendOTP} >
                      Resend OTP
                  </p>
              } */}
                  </div>
                </div>
                {formatTime(timeLeft) == "00:00" ? null : (
                  <Form.Item>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "10px",
                        flexDirection: "column",
                      }}
                    >
                      <Button
                        type="primary"
                        // htmlType='submit'
                        className="login-form-button"
                        onClick={handleValidateOtpClick}
                      >
                        Validate OTP
                      </Button>
                    </div>
                  </Form.Item>
                )}
              </>
            ) : null}

            {passwordField && (
              <>
                <div>New Password</div>
                <Form.Item
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "Password is required",
                    },
                    {
                      pattern: /^(?=.*[a-z])/,
                      message:
                        "Password must contain at least one lowercase letter",
                    },
                    {
                      pattern: /^(?=.*[A-Z])/,
                      message:
                        "Password must contain at least one uppercase letter",
                    },
                    {
                      pattern: /^(?=.*\d)/,
                      message: "Password must contain at least one number",
                    },
                    {
                      pattern: /^(?=.*[!@#$%^&*])/,
                      message:
                        "Password must contain at least one special character (!@#$%^&*)",
                    },
                    {
                      min: 8,
                      message: "Password must be at least 8 characters long",
                    },
                  ]}
                >
                  <Input.Password
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Enter New Password"
                    autoComplete="off"
                  />
                </Form.Item>
                <div>Confirm Password</div>
                <Form.Item
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    validateConfirmPassword,
                  ]}
                >
                  <Input.Password
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Re-Enter New Password"
                    autoComplete="off"
                  />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      type="primary"
                      // htmlType='submit'
                      className="login-form-button"
                      onClick={onPressChangePassword}
                    >
                      Change password
                    </Button>
                  </div>
                </Form.Item>
              </>
            )}
          </Form>
        </div>
        {/* <div style={{ textAlign: "center" }}>Terms of Use</div>
        <div style={{marginTop: '7%'}}>
          <AppIcons  />
        </div> */}
      </div>
    </div>
  );
};

export default ForgotPassword;
