import React from "react";

const BankDetails = (data) => {
  const bankData = data?.bankData;
  const dematData = data?.data[0]?.account_Holder;
  const bankIFSCData = data?.ifscData;
  return (
    <>
      <h2>
        <u>Bank Details</u>
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "4%",
            width: "50%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ width: "30%" }}>Bank Name :</p>
            <p style={{ width: "40%" }}>
              {bankData?.accountbankdata?.bank_name
                ? bankData?.accountbankdata?.bank_name
                : "Not Available"}
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ width: "30%" }}>Account Number :</p>
            <p style={{ width: "40%" }}>
              {bankData?.accountbankdata?.bank_account_number}
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ width: "30%" }}>Account Type :</p>
            <p style={{ width: "40%" }}>
              {bankData?.accountbankdata?.account_type}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "4%",
            width: "50%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ width: "20%" }}>IFSC code :</p>
            <p style={{ width: "40%" }}>
              {bankData?.accountbankdata?.bank_ifsc}
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ width: "20%" }}>MICR code :</p>
            <p style={{ width: "40%" }}>
              {bankData?.accountbankdata?.bank_micr ? (
                <span>{bankData?.accountbankdata?.bank_micr}</span>
              ) : (
                <span>Not Available</span>
              )}
            </p>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <p style={{ width: "20%" }}>Address:</p>
            {bankData?.accountbankdata?.bank_ifsc === null ||
            bankIFSCData === null ? (
              "Not Available"
            ) : (
              <p style={{ width: "70%" }}>
                {bankIFSCData?.address ?? "NA"}, {bankIFSCData?.city1 ?? "NA"},{" "}
                {bankIFSCData?.state_name ?? "NA"}
              </p>
            )}
          </div>
        </div>
      </div>
      {dematData?.demat_flag === "Y" ? (
        <>
          <h2>
            <u>Demat Details</u>
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "2%",
                width: "50%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ width: "40%" }}>Demat Participant Name :</p>
                <p style={{ width: "40%" }}>
                  {dematData?.depository_participant_name}
                </p>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ width: "40%" }}>DP ID :</p>
                <p style={{ width: "40%" }}>{dematData?.dp_id}</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "2%",
                width: "50%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p style={{ width: "40%" }}>Beneficiary Account Number :</p>
                <p style={{ width: "40%" }}>
                  {dematData?.benificiary_account_num ? (
                    <span>{dematData?.benificiary_account_num} </span>
                  ) : (
                    <span>Not Available</span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default BankDetails;
