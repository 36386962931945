import React, { useState, useEffect } from "react";
import { Button } from "antd";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import moment from "moment";
import { useStateContext } from "../../contexts/ContextProvider";
import axios from "axios";

const UcrReports = ({ data, date, fundHouse }) => {
  const { apiBaseUrl, user_id } = useStateContext();
  const [exportButton, setExportButton] = useState(true);
  const [workflowData, setWorkflowData] = useState([]);

  var workflowdate = moment().utcOffset("+05:30").format();
  const [pageLoadingTimeStamp, setPageLoadingTimeStamp] =
    useState(workflowdate);

  useEffect(() => {
    // set the export button to false, if data is there it will change to true or else it will be false
    setExportButton(false);
    if (data.length === 0) {
      setExportButton(true);
    }
    //console.log('ucrdate',date)
  }, [data]);

  useEffect(() => {
    getWorkflowAPI();
  }, []);

  const getWorkflowAPI = () => {
    // console.log(`${apiBaseUrl}/VWorkflow/getworkflowdata`, "workflowData");
    fetch(`${apiBaseUrl}/VWorkflow/getworkflowdata?wfseqcode=URDTR`)
      .then((repsonse) => repsonse.json())
      .then((json) => {
        //console.log(json);
        setWorkflowData(json.responseData);
      });
  };

  const createDownloadFirstUcrData = () => {
    // Downloading the First UC Report
    handleFirstUcrExport().then((url) => {
      // Calling the handleFirstUcrExport function and converting the data to excel for downloading
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        `UnitCapitalReport_${date}.xls`
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const insertWorkflowAPI = () => {
    var submitTimeStamp = moment().utcOffset("+05:30").format();
    let pageLoadingTimeStampInSeconds = moment(pageLoadingTimeStamp).seconds();
    let submitTimeStampInSeconds = moment(submitTimeStamp).seconds();
    let actualAHT_Seconds = Math.abs(
      submitTimeStampInSeconds - pageLoadingTimeStampInSeconds
    );

    // console.log(
    //   submitTimeStamp,
    //   pageLoadingTimeStampInSeconds,
    //   submitTimeStampInSeconds,
    //   actualAHT_Seconds,
    //   user_id,
    //   "*********"
    // );

    var apiInsertWorkFlowData = {
      worfklow_item_create_ts: pageLoadingTimeStamp,
      workflow_item_reference_value: user_id,
      isactive: true,
      workflow_id: workflowData[0].workflow_id,
      workflow_step_id: workflowData[0].worflow_step_id,
      system_feature_operation_id: 1,
      assigned_by_user_id: user_id,
      assigned_to_user_id: user_id,
      completed_by_user_id: user_id,
      assigned_ts: pageLoadingTimeStamp,
      completed_ts: pageLoadingTimeStamp,
      updation_date: pageLoadingTimeStamp,
      creation_by: user_id,
      creation_date: pageLoadingTimeStamp,
      workflow_step_json_data: "UCR report has been generated",
      workflow_status: "completed",
      actual_aht_seconds: actualAHT_Seconds,
      worflow_step_sequence_number:
        workflowData[0].worflow_step_sequence_number,
      // application_num: jsonData.ih_number,
      client_id: fundHouse,
    };

    // console.log("Updated UCR Insert Work Flow", apiInsertWorkFlowData);

    axios
      .post(`${apiBaseUrl}/VWorkFlow/insertWorkflow`, apiInsertWorkFlowData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        let temp = response.data.responseData;

        console.log(
          "insertWorkflowAPI of Credit Marking response........",
          temp
        );
      })
      .catch((error) => {
        console.log(error);
        //alert("something went wrong, please try again later");
      });
  };

  const workbook2blob = (workbook) => {
    // Converting the data to excel
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    // Conversion of data to excel format and sorting of the data

    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleFirstUcrExport = () => {
    // Creating the first UC Report Excel Format
    const title = [
      {
        A: `Unit Captial Report of  "${
          data[0].client_name
        }"  as on Date ${moment(date).format("DD-MM-YYYY")}`,
      },
      {},
    ];

    let firstTable = [
      {
        A: "Sno",
        B: "Scheme Name",
        C: "Class Name",
        D: "Opening Units",
        E: "Purchase Units",
        F: "SwitchIn Units",
        G: "ReInvested Units",
        H: "Redemption Units",
        I: "SwitchOut Units",
        J: "Closing Units",
        K: "AUM",
        L: "NAV",
        M: "NAV AS ON",
      },
    ];

    let firstTotal = [
      {
        A: "", // this will be for total and it will be empty
      },
    ];

    //Pushing data to first ucr table
    for (let i = 0; i < data.length; i++) {
      firstTable.push({
        // change mapping here
        A: data[i].sno,
        B: data[i].fund_name,
        C: data[i].investment_class_name,
        D: data[i].opening_units,
        E: data[i].purchase_units,
        F: data[i].switch_units,
        G: data[i].re_invested_units,
        H: data[i].redemption_units,
        I: data[i].switch_out_units,
        J: data[i].closing_units,
        K: data[i].transactionamount,
        L: data[i].nav || "",
        M: data[i].nav_date || "",
      });
    }
    firstTotal.push({
      A: "Total Transaction",
      B: data.length,
    });

    firstTable = [{ A: "" }]
      .concat([""])
      .concat([""])
      .concat(firstTable)
      .concat([""])
      .concat([""])
      .concat(firstTotal);

    const finalData = [...title, ...firstTable];

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "Unit Capital Report");

    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "Sno" ? headerIndexes.push(index) : null
    );

    const dataInfo = {
      titleCell: "A1",
      titleRange: "A1:C2",
      tbodyRange: `A2:CZ${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:M${headerIndexes[0] + 1}`
          : null,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    // Adding style to the excel sheet
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",
        });

        sheet.column("A").width(20);
        sheet.column("B").width(30);
        sheet.column("C").width(25);
        sheet.column("D").width(25);
        sheet.column("E").width(25);
        sheet.column("F").width(25);
        sheet.column("G").width(25);
        sheet.column("H").width(25);
        sheet.column("I").width(25);
        sheet.column("J").width(25);
        sheet.column("K").width(25);
        sheet.column("L").width(25);
        sheet.column("M").width(25);

        sheet.range(dataInfo.titleRange).merged(true).style({
          // fill: "#FFFFFF",
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
          border: true,
        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: "center",
          });
        }

        sheet.range(dataInfo.theadRange).style({
          bold: true,
          horizontalAlignment: "center",
          border: true,
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <div>
      <Button
        onClick={() => {
          createDownloadFirstUcrData();
          insertWorkflowAPI();
        }}
        disabled={exportButton}
        style={{
          width: "140px",
          height: "33px",
          backgroundColor: "#A3020C",
          color: "white",
          borderRadius: 5,
        }}
      >
        Export as Excel
      </Button>
    </div>
  );
};

export default UcrReports;
