import React, {useEffect, useState} from 'react'
import { AiOutlineMenu } from 'react-icons/ai';
import { BsChatLeft , BsPower } from 'react-icons/bs';
import { RiNotification3Line } from 'react-icons/ri';
import { MdKeyboardArrowDown } from 'react-icons/md';
import {Tooltip } from 'antd';
import {Link} from 'react-router-dom';
import {Chat , Notifications , UserProfile} from '.'
import logo from '../assets/logo.png';
import { useStateContext } from '../contexts/ContextProvider';

const Navbar = () => {
  const { activeMenu , setActiveMenu , isClicked ,handleClick ,
    screenSize , setScreenSize , 
    user_first_name,handleLogOut, apiBaseUrl, accountNumber} = useStateContext();

  useEffect(() => {
  const handleResize = () => setScreenSize(window.innerWidth)
  window.addEventListener('resize', handleResize);
  handleResize();

  return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [accountName, setAccountName] = useState(null);
  // const fetchData = () => {
  //   fetch(`${apiBaseUrl}/VAccount/GetProfilebyAccnum/${accountNumber}`)
  //   .then(response => response.json())
  //   .then(json => {setAccountName(json.responseData?.accountHolderdata.filter(obj => obj.account_Holder.account_holder_number == 1)[0].account_Holder.first_name);
  //     console.log(json.responseData?.accountHolderdata.filter(obj => obj.account_Holder.account_holder_number == 1)[0].account_Holder.first_name)}
  //   )
  //   .catch(error => alert('something went error'));
  // }
  
  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(()=>{
    if(screenSize <= 900){
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  },[screenSize])


  const NavButton = ({title , customfunc, icon , color , dotColor}) => (
    <Tooltip title={title} placement='bottom'>
      <button type='button' onClick={customfunc}
      style={{color}}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
      >
        <span style={{background: dotColor}}
        className='absolute inline-flex rounded-full h-2 w-2 right-2 top-2'/>
          {icon}
      </button>
    </Tooltip>
  )
  return (
    <div className='flex justify-between p-2
    md:mx-6 relative' style={{backgroundColor: '#F1F6F9'}}>
      <NavButton title="Menu" 
      customfunc={()=>
        setActiveMenu(
          (prevActiveMenu) => !prevActiveMenu
          )
        }
      color='black' 
      icon={<AiOutlineMenu/>}/>
      <Link to='/Dashboard'>
        <img style={{width:120}} className= {`ml-32 ${activeMenu? 'invisible': ''}`} src={logo} alt="company logo" />
      </Link>

    </div>
  )
}

export default Navbar