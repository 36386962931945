export const selectionReportData = [
    {
      id: 1,
      title: 'Daily Transaction Report',
    },
    {
      id: 2,
      title: 'Unit Capital Report',
    },
    {
      id: 3,
      title: 'Unit Holding Report',
    },
    {
      id: 4,
      title: 'Transaction Register Report',
    },
    {
      id: 5,
      title: 'Service Request Report',
    },
    {
      id: 6,
      title: 'Credit Marking Report',
    },
    {
      id: 7,
      title: 'NAV Report',
    },
  ];
  
  export const selectionAccountData = [
    {
      id: 9128904385,
      name: '9128904385',
    },
    {
      id: 9854989981,
      name: '9854989981',
    },
    {
      id: 9159902105,
      name: '9159902105',
    },
    {
      id: 9049645515,
      name: '9049645515',
    },
    {
      id: 9846912344,
      name: '9846912344',
    },
    {
      id: 9025401985,
      name: '9025401985',
    },
    {
      id: 9899046188,
      name: '9899046188',
    },
    {
      id: 9809995866,
      name: '9809995866',
    },
    {
      id: 9764953071,
      name: '9764953071',
    },
    {
      id: 9089895458,
      name: '9089895458',
    },
    {
      id: 9969904046,
      name: '9969904046',
    },
    {
      id: 9045628725,
      name: '9045628725',
    },
    {
      id: 9869502809,
      name: '9869502809',
    },
    {
      id: 9989950944,
      name: '9989950944',
    },
    {
      id: 9061563264,
      name: '9061563264',
    },
    {
      id: 9064850886,
      name: '9064850886',
    },
    {
      id: 9069845505,
      name: '9069845505',
    },
    {
      id: 9069995152,
      name: '9069995152',
    },
    {
      id: 9064520696,
      name: '9064520696',
    },
    {
      id: 9069815826,
      name: '9069815826',
    },
    {
      id: 9061852911,
      name: '9061852911',
    },
  ];