import React, {useState, useEffect}  from 'react'
import { useStateContext } from '../contexts/ContextProvider'
import Links from '../components/Links';
import {Table} from 'antd';
import search from '../assets/Mag glass.png';
import newPurchase from '../assets/Savings.png';
import Drawdown from '../assets/Calendar.png'
import Alert from '../assets/Alert.png';
import moment from 'moment';
import axios from 'axios';
const Dashboard = () => {
  const { apiBaseUrl, clientId, formatNumberWithCommas} = useStateContext();
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const fetchData = async () => {
    axios.get(`${apiBaseUrl}/VTransaction/GetTransactionsCommon/${1}`)
    .then(response => {setData(response.json.responseData); console.log(response);})
    .catch(error => console.log(`Error Loading Data : ${error.message}`));
    setShowTable(true);
  }
  useEffect(() => {
    fetchData();
    data.forEach((ele , index)=>{
      ele.sno = index + 1;
      ele.transaction_ts = moment(ele.transaction_ts).format('DD/MM/YYYY').toString();
      ele.fund_nav_date = moment(ele.fund_nav_date).format('DD/MM/YYYY').toString();
      ele.committmentamount = ele.committmentamount ? formatNumberWithCommas(ele.committmentamount) : 'Not Available';
      ele.application_number = ele.application_number ? ele.application_number : 'Not Available';
      ele.transaction_status = ele.transaction_status === 'P' ? 'Pending' : ele.transaction_status === 'Y' ? 'Success' : 'Null';
    })
    setTableData(data);
  }, [])



  return (
  <>
    <div style={{backgroundColor: 'white', width: '98%', borderRadius: 10,padding: '1%', margin : '0 auto'}}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%',gap: '1%'}}>
        <div style={{width: '60%',  display: 'flex', flexDirection: 'column'}}>
          <div style={{backgroundColor: '#D1E3CC', borderRadius: 10, paddingLeft: '4%', paddingRight: '4%'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',}}>
              <div>
                <img src={newPurchase}  />
              </div>
              <div style={{fontWeight: 'bold', marginTop : '5%'}}>New Purchase</div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',marginTop: '5%', marginBottom: '1%'}}>
              <div>Physical : 10</div>
              <div>Digital : 10</div>
            </div>
          </div>
          <div style={{backgroundColor: '#D5D1F5', marginTop:'5%', borderRadius: 10, paddingLeft: '4%', paddingRight: '4%'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',}}>
              <div> <img src={Drawdown}  /></div>
              <div style={{fontWeight: 'bold', marginTop : '5%'}}>Drawdwown</div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', marginBottom: '1%'}}>
              <div style={{textAlign: 'right', marginRight: '3%'}}>Defaulters : 2</div>
              <div style={{textAlign: 'right', marginRight: '3%'}}>Requested for Visit : 2</div>
              <div style={{textAlign: 'right', marginRight: '3%'}}>Upcoming Events: 2</div>
            </div>
          </div>
        </div>
        <div style={{backgroundColor: '#ECE1D5', width: '100%', borderRadius: 10, paddingLeft: '2%', paddingRight: '2%'}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between',}}>
            <div> <img src={Alert}  /></div>
            <div style={{fontWeight: 'bold', marginTop : '5%',marginRight: '5%'}}>Alerts</div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent:'space-between', marginTop : '10%'}}>
            <div style={{display: 'flex', flexDirection: 'column', width:'100%',}}>
              <div style={{textAlign: 'left', marginLeft: '3%'}}>Nigo Pending : 2</div>
              <div style={{textAlign: 'left', marginLeft: '3%'}}>Abandoned : 2</div>
              <div style={{textAlign: 'left', marginLeft: '3%'}}>Requested for Visit: 2</div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column' , width:'100%' }}>
              <div style={{textAlign: 'right', marginRight: '3%'}}>Signatures Pending : 2</div>
              <div style={{textAlign: 'right', marginRight: '3%'}}>KYC Pending : 2</div>
              <div style={{textAlign: 'right', marginRight: '3%'}}>Open Tickets: 2</div>
              <div style={{textAlign: 'right', marginRight: '3%'}}>Drawdown Defaulters: 2</div>
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{backgroundColor: '#002F8F', padding: '1%', marginTop: '1%', borderRadius: 10}}>
        <div>
          <img src={search} style={{width: 30, height: 30}} />
        </div>
      </div> */}
    </div>
    <div style={{marginLeft: '4%', alignSelf: 'center', width: '100%', marginTop: '2%', marginBottom: '4%'}}>
      <Links />
    </div>
    <div>
      {/* {
        showTable?
        <Table 
          dataSource={tableData}
          bordered
          pagination={{ pageSize: 5}}
          style={{margin: '0 auto', width: '98%', marginTop: '2%'}}
          columns={[
            {
              title: 'Fund',
              dataIndex: 'client_name',
              key: 'Fund',
            },
            {
              title: 'Scheme',
              dataIndex: 'fund_Description',
              key: 'Scheme',
            },
            {
              title: 'Class',
              dataIndex: 'class_category',
              key: 'Class',
            },
            {
              title: 'Application Number',
              dataIndex: 'application_number',
              key: 'Application Number',
            },
            {
              title:  (
                <>
                  Investor<br />Name
                </>
              ),
              dataIndex: 'investor_name',
              key: 'Investor Name',
            },
            {
              title: 'Commitment Amount',
              dataIndex: 'committmentamount',
              key: 'Commitment Amount',
            },
            {
              title: (
                <>
                  Transaction<br />Date
                </>
              ),
              dataIndex: 'transaction_ts',
              key: 'Transaction Date',
            },
            {
              title: 'Value Date',
              dataIndex: 'fund_nav_date',
              key: 'Value Date',
            },
            {
              title: 'Source',
              dataIndex: 'transaction_source_type',
              key: 'Source',
            },
            {
              title: 'Status',
              dataIndex: 'transaction_status',
              key: 'Status',
            }
          ]}
        />
      :null
      } */}
    </div>
  </>
  )
}

export default Dashboard