import React, {useState, useEffect} from 'react';
import {useStateContext} from '../../contexts/ContextProvider';
import axios from 'axios';
import LoadingScreen from 'react-loading-screen';
import SuggestedQueryList from './SuggestedQuery/SuggestedQueryList';

const QuerySelection = () => {
  const {apiBaseUrl} = useStateContext();
  const [searchFields, setSearchFields] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/VAccount/GetSearchFields`)
      .then(response => {
        setSearchFields(response.data.responseData);
        console.log('SearchFields', response.data.responseData);
        //console.log('SearchFields',searchFields)
        setLoading(false);
      })
      .catch(error => {
        alert('Something went wrong').console.log(error);
      });
  }, []);

  return (
    <div style={{zIndex: 1000}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          width: '100%',
        }}></div>
      {loading ? (
        <LoadingScreen
          loading={true}
          bgColor="transparent"
          spinnerColor="#000"
          textColor="#000"
          text="Loading...">
          <h1>Loading...</h1>
        </LoadingScreen>
      ) : (
        <SuggestedQueryList searchFields={searchFields} />
      )}
    </div>
  );
};

export default QuerySelection;
