import React, { useState, useEffect } from "react";
import { Table } from "antd";
import moment from "moment";
import { addIndianCommas } from "../../../components/utils/helperFunction";
import { useStateContext } from "../../../contexts/ContextProvider";

const TransactionDetails = (data) => {
  const transactionData = data?.data;
  const { accountNumber } = useStateContext();
  const [tableData, setTableData] = useState([]);
  const [filteredTransactionsInfo, setFilteredTransactionsInfo] = useState({});

  useEffect(() => {
    let transactionIhnumbers = [];
    transactionData?.length > 0 &&
      transactionData.forEach((ele, index) => {
        ele.sno = index + 1;
        ele.fund_nav_date = ele?.fund_nav_date
          ? moment(ele.fund_nav_date).format("DD/MM/YYYY").toString()
          : "Not Available";
        ele.transaction_status =
          ele.transaction_status === "P"
            ? "Pending"
            : ele.transaction_status === "Y"
            ? "Approved"
            : ele.transaction_status === "R"
            ? "Rejected"
            : "Not Available";
        ele.transaction_amount =
          ele.transaction_type === "Stamp Duty"
            ? ele.transaction_amount
            : addIndianCommas(ele.transaction_amount);
        transactionIhnumbers.push(ele.ih_number, accountNumber);
      });
    setTableData(transactionData);
  }, [transactionData]);

  const filterTransactionsData = (transactionData) =>
    transactionData.map((item) => ({
      key: item,
      value: item,
      text: item,
    }));

  const handleTransactionsTableChange = (_, filters) => {
    setFilteredTransactionsInfo(filters);
  };

  const transactionColumns = [
    {
      title: "S. No",
      dataIndex: "sno",
      width: 67,
    },
    {
      title: (
        <>
          Transaction
          <br />
          Date
        </>
      ),
      dataIndex: "fund_nav_date",
      width: 140,
    },
    {
      title: "IH number",
      dataIndex: "ih_number",
      width: 160,
      filters: filterTransactionsData(
        transactionData
          .map((item) => item.ih_number)

          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      filteredValue: filteredTransactionsInfo.ih_number || null,
      onFilter: (value, record) => record.ih_number.includes(value),
    },
    {
      title: (
        <>
          Transaction
          <br />
          Type
        </>
      ),
      dataIndex: "transaction_type",
      width: 160,
      filters: filterTransactionsData(
        transactionData
          .map((item) => item.transaction_type)

          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      filteredValue: filteredTransactionsInfo.transaction_type || null,
      onFilter: (value, record) => record.transaction_type.includes(value),
    },
    {
      title: "NAV",
      dataIndex: "nav",
      width: 90,
    },
    {
      title: "Amount",
      dataIndex: "transaction_amount",
      width: 120,
      align: "right",
    },
    {
      title: "Status",
      dataIndex: "transaction_status",
      width: 150,
      filters: filterTransactionsData(
        transactionData
          .map((item) => item.transaction_status)
          .filter((value, index, self) => self.indexOf(value) === index)
      ),
      filteredValue: filteredTransactionsInfo.transaction_status || null,
      onFilter: (value, record) => record.transaction_status.includes(value),
      render: (text, record) => {
        if (record.rejection_reason) {
          return `${text} (${record.rejection_reason})`;
        }
        return text;
      },
    },
  ];
  return (
    <>
      <h2>
        <u>Transactions</u>
      </h2>
      <Table
        style={{
          display: "block",
          justifyContent: "center",
          marginTop: "3%",
          width: "100%",
        }}
        columns={transactionColumns}
        dataSource={tableData}
        onChange={handleTransactionsTableChange}
        pagination={{
          pageSize: 10,
        }}
        scroll={{
          y: 330,
        }}
        bordered
      />
    </>
  );
};

export default TransactionDetails;
