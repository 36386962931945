import React, { useState, useEffect } from "react";
import "antd/dist/antd.min.css";
import { useStateContext } from "../../../contexts/ContextProvider";
import DownloadSOA from "../../../components/DownloadSOA";
import "./QueryProfileDetails.css";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import DrawdownDetails from "./DrawdownDetails";
import BankDetails from "./BankDetails";
import GuardianDetails from "./GuardianDetails";
import FirstHolderDetails from "./FirstHolderDetails";
import NomineeDetails from "./NomineeDetails";
import ServiceRequestDetails from "./ServiceRequestDetails";
import TransactionDetails from "./TransactionDetails";
import DocumentDetails from "./DocumentDetails";
import SecondHolderDetails from "./SecondHolderDetails";
import ThirdHolderDetails from "./ThirdHolderDetails";

const QueryProfileDetails = () => {
  const { apiBaseUrl, accountNumber } = useStateContext();
  const folioNumber = accountNumber;
  const [profData, setProfData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nctData, setNctData] = useState([]);
  const [ihNumbersData, setIhNumbersData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [drawdownData, setDrawdownData] = useState([]);
  const [toggleState, setToggleState] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [accountNomineeData, setAccountNomineeData] = useState([]);
  const [jointHolder1Data, setJointHolder1Data] = useState([]);
  const [jointHolder2Data, setJointHolder2Data] = useState([]);
  const [accountGuardianData, setAccountGuardianData] = useState([]);
  const [IFSCData, setIFSCData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [clientId, setClientId] = useState("");
  const [fundId, setFundId] = useState("");
  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [documentTypeData, setDocumentTypeData] = useState([]);
  const getDocumentTypeData = async (clientId) => {
    await axios
      .get(`${apiBaseUrl}/Common/Digitaldocinfodetails/${clientId}`)
      .then((response) => {
        const filteredData = response.data.responseData.filter(
          (item) => item.kyc_document_id === 115 || item.kyc_document_id === 116
        );
        setDocumentTypeData(filteredData);
      });
  };

  const fetchData = async () => {
    try {
      const resp = await axios.get(
        `${apiBaseUrl}/VAccount/Getvadiledaccount/${folioNumber}`
      );
      const res = await axios.get(
        `${apiBaseUrl}/VAccount/GetProfilebyAccnum?Accountnum=${folioNumber}`
      );
      const profileResData = await resp.data?.responseData;
      const resData = await res.data;
      if (res.data.code === -100) {
        console.log("crashed");
        return; // Return early to stop further processing
      }
      const accountData = resData.responseData?.accountHolderdata;
      const bankData = resData.responseData?.bankdata;
      const guardianData = resData.responseData?.guardianlist;
      const accountNomineeData = resData.responseData?.nomineelist;
      const jointHolder1Data = resData.responseData?.accountHolderdata.filter(
        (obj) => obj.account_Holder.account_holder_number === 2
      );
      const jointHolder2Data = resData.responseData?.accountHolderdata.filter(
        (obj) => obj.account_Holder.account_holder_number === 3
      );
      const fundId = resData.responseData?.classdata[0]?.fund_id;
      const clientId = resData.responseData?.classdata[0]?.client_id;
      getDocumentTypeData(clientId);

      const [nctResponse, drawdownResponse, transactionResponse, ifscResponse] =
        await Promise.all([
          axios.get(
            `${apiBaseUrl}/VNct/GetNCTbyAccountNumber/${folioNumber}/${clientId}`
          ),
          axios.get(
            `${apiBaseUrl}/VDrawdown/FetchDrawdownDetails/${clientId}?accountnumber=${folioNumber}`
          ),
          axios.get(
            `${apiBaseUrl}/VTransaction/GetTransactionsCommon/${clientId}?accountnumber=${folioNumber}&rejecttxn=true`
          ),
          axios.get(
            `${apiBaseUrl}/Vlookup/Rbidetails/${bankData?.accountbankdata?.bank_ifsc}`
          ),
        ]);

      const nctTrxnData = await nctResponse.data.responseData;
      const drawdownData = await drawdownResponse.data.responseData;
      const transactionsData = await transactionResponse.data.responseData;
      const ifscData = ifscResponse.data.responseData;

      const transactionIhnumbers = await transactionsData
        .map((ele) => [ele.ih_number])
        .flat();
      const nctIhNumbers = await nctTrxnData
        .map((ele) => [ele.nct_ih_number])
        .flat();
      const ihNumbers = [...transactionIhnumbers, ...nctIhNumbers];

      setFilteredData(
        accountData.filter(
          (obj) => obj.account_Holder.account_holder_number === 1
        )
      );
      setBankData(bankData);
      setProfData(profileResData);
      setAccountGuardianData(guardianData);
      setAccountNomineeData(accountNomineeData);
      setJointHolder1Data(jointHolder1Data);
      setJointHolder2Data(jointHolder2Data);
      setFundId(fundId);
      setClientId(clientId);
      setNctData(nctTrxnData);
      setDrawdownData(drawdownData);
      setTransactionData(transactionsData);
      setIFSCData(ifscData);
      setLoading(false);
      setIhNumbersData(ihNumbers);
      setProfData(profileResData);
    } catch (error) {
      alert("something went wrong");
    }
  };

  return (
    <>
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          fontSize: "30px",
          color: "#A3020C",
        }}
      >
        Profile Details
      </h1>
      {loading ? (
        <div>
          <LoadingScreen
            loading={true}
            bgColor="transparent"
            spinnerColor="#000"
            textColor="#000"
            text="Loading..."
          >
            <h1>Loading...</h1>
          </LoadingScreen>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            margin: "0 auto",
            marginBottom: "4%",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            width: "98%",
            height: "70%",
          }}
        >
          <div className="container">
            <div className="bloc-tabs">
              <button
                className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(1)}
              >
                Personal{"\n"}Details
              </button>
              <button
                className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(2)}
              >
                Bank Details
              </button>
              <button
                className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(3)}
              >
                Transactions
              </button>
              <button
                className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(4)}
              >
                Drawdowns
              </button>
              <button
                className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(5)}
              >
                Service Requests
              </button>
              <button
                className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
                onClick={() => toggleTab(6)}
              >
                Interaction History
              </button>
            </div>
            <div className="content-tabs">
              {/* Holder Details */}
              <div
                className={
                  toggleState === 1 ? "content  active-content" : "content"
                }
              >
                {fundId && clientId && (
                  <DownloadSOA
                    clientID={clientId}
                    fundID={fundId}
                    accountNumber={folioNumber}
                  />
                )}
                <FirstHolderDetails
                  firstHolderData={filteredData}
                  folioNumber={folioNumber}
                />
                <SecondHolderDetails secondHolderData={jointHolder1Data} />
                <ThirdHolderDetails thirdHolderData={jointHolder2Data} />
                <GuardianDetails guardianData={accountGuardianData} />
                <NomineeDetails nomineeData={accountNomineeData} />
              </div>

              {/* Bank Details */}
              <div
                className={
                  toggleState === 2 ? "content  active-content" : "content"
                }
              >
                <BankDetails
                  data={filteredData}
                  bankData={bankData}
                  ifscData={IFSCData}
                />
              </div>

              {/* Transaction Details */}
              <div
                className={
                  toggleState === 3 ? "content  active-content" : "content"
                }
              >
                <TransactionDetails data={transactionData} />
              </div>

              {/* Drawdown Details */}
              <div
                className={
                  toggleState === 4 ? "content  active-content" : "content"
                }
              >
                <DrawdownDetails data={drawdownData} />
              </div>

              {/* Service Requests */}
              <div
                className={
                  toggleState === 5 ? "content  active-content" : "content"
                }
              >
                <ServiceRequestDetails data={nctData} />
              </div>

              {/* Investor Documents */}
              <div
                className={
                  toggleState === 6 ? "content  active-content" : "content"
                }
              >
                <DocumentDetails
                  data={ihNumbersData}
                  investorName={filteredData[0]?.account_Holder?.legal_name}
                  classCategory={profData[0]?.class_name}
                  fundName={profData[0]?.fund_name}
                  clientName={profData[0]?.client_name}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default QueryProfileDetails;
