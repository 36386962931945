import React from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { Tooltip } from "antd";
import { useStateContext } from "../contexts/ContextProvider";
import logo from "../assets/logo.png";
import { HomeOutlined } from "@ant-design/icons";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaFileDownload } from "react-icons/fa";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { LogoutOutlined } from "@ant-design/icons";
import { BsArrowLeftRight } from "react-icons/bs";
import { RiContactsLine } from "react-icons/ri";
import moment from "moment";
const SideBar = () => {
  const {
    activeMenu,
    setActiveMenu,
    screenSize,
    last_login_date,
    user_first_name,
    user_last_name,
    handleLogOut,
  } = useStateContext();
  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-green-400 text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  const handleCloseSidebar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const links = [
    {
      links: [
        {
          displayName: "Dashboard",
          name: "Dashboard",
          icon: <HomeOutlined />,
        },
        {
          displayName: "Transactions",
          name: "Transactions",
          icon: <BsArrowLeftRight />,
        },
        {
          displayName: "Service Requests",
          name: "ServiceRequests",
          icon: <RiCustomerService2Fill />,
        },
        {
          displayName: "Query",
          name: `QuerySelection`,
          icon: <RiContactsLine />,
        },
        {
          displayName: "Document Upload",
          name: "DocumentUpload",
          icon: <RiContactsLine />,
        },
        // {
        //   displayName: "Account Statement",
        //   name: "AccountStatement",
        //   icon: <MdOutlineSupervisorAccount />,
        // },
        {
          displayName: "Reports",
          name: "Reports",
          icon: <FaFileDownload />,
        },
        // {
        //   displayName: "Utilities",
        //   name: "Utilities",
        //   icon: <MdOutlineSupervisorAccount />,
        // },
        {
          displayName: "Profile",
          name: "Profile",
          icon: <MdOutlinePersonalInjury />,
        },
        // {
        //   displayName: "Settings",
        //   name: "Settings",
        //   icon: <MdOutlineSupervisorAccount />,
        // },
        // {
        //   displayName: "Academy",
        //   name: "Academy",
        //   icon: <MdOutlineSupervisorAccount />,
        // },
      ],
    },
  ];

  return (
    <div
      className=" h-screen
     lg:z-50 md:z-50 sm:z-50
    md:overflow-hidden overflow-auto
    md:hover:overflow-auto pb-10"
      style={{ backgroundColor: "#B3C6E7" }}
    >
      {activeMenu && (
        <>
          <div
            className="flex justify-between
      items-center"
          >
            <Tooltip placement="bottom" title={"close"}>
              <button
                type="button"
                onClick={() =>
                  setActiveMenu((prevActiveMenu) => !prevActiveMenu)
                }
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </Tooltip>
          </div>
          <Link
            to="/Dashboard"
            onClick={handleCloseSidebar}
            className="items-center gap-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
          >
            <img
              style={{ margin: "0 auto", width: "60%" }}
              src={logo}
              alt="company logo"
            />
          </Link>
          <h4 className="mt-5 text-center">
            Hello {user_first_name} {user_last_name}{" "}
          </h4>
          <p className="mt-1 pl-3 pr-3 text-center">
            Your last logged in time was
            <br /> on {moment(last_login_date).format("D MMMM YYYY")} at{" "}
            {moment(last_login_date).format("hh:mm A")}
          </p>
          <div className="mt-5">
            {links.map((item) => (
              <div key={item.title}>
                {item.links.map((link) => (
                  <NavLink
                    to={`/${link.name}`}
                    key={link.name}
                    onClick={handleCloseSidebar}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                    style={{ backgroundColor: "#002060" }}
                  >
                    <div style={{ color: "white" }}>{link.icon}</div>
                    <span className="capitalize" style={{ color: "white" }}>
                      {link.displayName}
                    </span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
          <div
            onClick={handleLogOut}
            className={({ isActive }) => (isActive ? activeLink : normalLink)}
            style={{
              backgroundColor: "#002060",
              display: "flex",
              alignItems: "center",
              padding: "3.4%",
              margin: "2%",
              borderRadius: "6px",
              cursor: "pointer",
            }}
          >
            <div style={{ color: "white", marginLeft: "2%" }}>
              <LogoutOutlined />
            </div>
            <span
              className="capitalize"
              style={{ color: "white", marginLeft: "8%" }}
            >
              Log Out
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default SideBar;
