import React, { useState } from "react";
import "antd/dist/antd.min.css";
import { Input, Form, Button, Modal } from "antd";
import { groupBy } from "lodash";
import { useStateContext } from "../../../contexts/ContextProvider";
import axios from "axios";
import QueryProfileData from "../QueryDetails/QueryProfileData";
import { useLocation } from "react-router-dom";

const MAX_INPUTS_PER_FIELD = 3;

const AdvanceQuerySelection = () => {
  const location = useLocation();
  const { searchData } = location.state || {};
  const { apiBaseUrl, client_id, user_id } = useStateContext();
  const groupedData = groupBy(searchData, "search_fieldid");
  const [inputs, setInputs] = useState([]);
  const [selectedQueryData, setSelectedQueryData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const scrollDownAfterSearch = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 10);
  };

  const searchAdvanceQuery = async () => {
    let typeofTransaction;
    let apiUrl = "";
    const apiData = inputs
      .map((input, subId) => ({ input, subId })) // combine input with subId
      .filter(
        ({ input }) => input !== undefined && input !== null && input !== ""
      ) // filter out undefined and null values and empty string for input
      .map(({ input, subId }) => ({
        // map the valid inputs to desired format
        search_sub_fieldid: subId,
        value: input,
      }));
    typeofTransaction = apiData.some(
      ({ search_sub_fieldid }) => search_sub_fieldid === 17
    )
      ? false
      : true;
    if (
      client_id === null ||
      client_id === undefined ||
      client_id === "" ||
      client_id === "null"
    ) {
      apiUrl = `${apiBaseUrl}/VAccount/getAdvancedSearchData/${user_id}?type=${typeofTransaction}`;
    } else {
      apiUrl = `${apiBaseUrl}/VAccount/getAdvancedSearchData/${user_id}?clientid=${client_id}&type=${typeofTransaction}`;
    }

    const uniqueApplicationNumbers = new Set();

    await axios
      .post(apiUrl, apiData)
      .then((response) => {
        const responseData = response.data.responseData;
        responseData.forEach((item) => {
          const applicationNumber = item.applicationnumber;
          if (!uniqueApplicationNumbers.has(applicationNumber)) {
            uniqueApplicationNumbers.add(applicationNumber);
            setSelectedQueryData((prevData) => [...prevData, item]);
          }
        });
        setShowTable(true);
        scrollDownAfterSearch();
        setIsModalVisible(false);
        if (responseData.length === 0) {
          setIsModalVisible(true);
          setShowTable(false);
        }
      })
      .catch((error) => {
        setShowTable(false);
        setIsModalVisible(true);
      });
  };

  const handleInputChange = (index, value, subId) => {
    const newInputs = [...inputs];
    newInputs[subId] = value;
    setInputs(newInputs);
  };

  const renderInputFields = (items, maxInputsPerField) => {
    const fields = {};
    items.forEach((item) => {
      const { search_sub_fieldid, search_sub_field_name, isactive } = item;
      if (!isactive) return; // Skip if isactive is false
      if (!fields[search_sub_fieldid]) {
        fields[search_sub_fieldid] = {
          search_sub_fieldid,
          search_sub_field_name,
          inputs: [],
        };
      }
      fields[search_sub_fieldid].inputs.push(item);
    });

    const inputsArr = Object.values(fields)
      .map((field) => field.inputs)
      .flat();
    const numCols = Math.ceil(inputsArr.length / maxInputsPerField);
    const columns = Array(numCols)
      .fill()
      .map((_, i) =>
        inputsArr.slice(i * maxInputsPerField, (i + 1) * maxInputsPerField)
      );

    return (
      <div
        key={`${items[0].search_fieldid}`}
        className="dark:text-gray-200 dark:bg-secondary-dark-bg m-2 rounded-md h-2/3"
      >
        <div className="bg-validus-blue text-white font-semibold text-base py-1 px-1 rounded mb-4">
          {items[0].search_field_name}
        </div>
        <div className="flex flex-wrap">
          {columns.map((column) => (
            <div
              key={column[0].search_sub_fieldid}
              className="flex flex-col w-1/2"
            >
              {column.map((input, index) => (
                <div key={input.search_sub_fieldid} className="flex-1">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label className="w-24 font-medium mb-5">
                      {input.search_sub_field_name}:-{" "}
                    </label>
                    <Form.Item
                      className="col-span-2"
                      name={input.search_sub_field_name}
                    >
                      <Input
                        className="m-2"
                        placeholder={`Enter ${input.search_sub_field_name}`}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            e.target.value,
                            input.search_sub_fieldid
                          )
                        }
                        value={inputs[input.search_sub_fieldid]}
                      />
                    </Form.Item>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          fontSize: "28px",
          color: "#A3020C",
        }}
      >
        Advanced Query Search
      </h1>
      <div className="grid grid-cols-2 gap-4 w-full">
        {Object.entries(groupedData).map(([fieldId, items]) =>
          renderInputFields(items, MAX_INPUTS_PER_FIELD)
        )}
      </div>
      <div className="flex justify-center mb-10">
        <Button
          onClick={searchAdvanceQuery}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "80px",
            height: "33px",
            backgroundColor: "#A3020C",
            color: "white",
            borderRadius: 5,
            marginTop: 30,
          }}
        >
          Search
        </Button>
      </div>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <p>No data found. Please Enter Correct Details.</p>
      </Modal>
      {showTable && <QueryProfileData data={selectedQueryData} />}
    </>
  );
};

export default AdvanceQuerySelection;
