import React, { useState } from "react";
import { Form, Input, Button, Modal } from "antd";
import "antd/dist/antd.css";
import "./Login.css";
import { useStateContext } from "../../../contexts/ContextProvider";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LeftNav from "../../../components/LeftNav";
import { AppIcons } from "../../../components";

const ChangePassword = () => {
  let navigate = useNavigate();
  const { apiBaseUrl } = useStateContext();

  const [userName, setUserName] = useState(
    localStorage.getItem("userName") || ""
  );

  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  );
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("newPassword") === value) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error("The Passwords that you entered do not match!")
      );
    },
  });

  const onPressChangePassword = () => {
    if (password !== "" && newPassword !== "" && confirmPassword !== "") {
      if (newPassword === confirmPassword) {
        let apiPostData = {
          username: userName,
          oldpassword: password,
          confirmpassword: confirmPassword,
          creation_by: 1,
          updation_by: 1,
          isactive: true,
          type: "C",
        };
        console.log("Password Change Request", apiPostData);

        axios
          .post(`${apiBaseUrl}/VUser/changePassword`, apiPostData)
          .then((response) => {
            console.log("Password Change Response", response.data.responseData);
            if (
              response.data.message === "Success" &&
              response.data.responseData !== "New password already exist"
            ) {
              // setPassword(confirmPassword);
              localStorage.setItem("password", confirmPassword);
              navigate("/Login");
              console.log(
                "Password changed successfully",
                "moved to login page"
              );
              Modal.success({
                title: "Success",
                content: "Password changed successfully",
                onOk() {},
              });
            } else if (
              response.data.message === "Success" &&
              response.data.responseData === "New password already exist"
            ) {
              Modal.error({
                title: "Error",
                content: "New password already exist",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        Modal.error({
          title: "Error",
          content: "New password and confirm password do not match",
        });
      }
    } else {
      Modal.error({
        title: "Error",
        content: "Please enter all the fields",
      });
    }
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  const [form] = Form.useForm();

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <LeftNav />
      <div style={{ width: "50%", backgroundColor: "#F1F6F9" }}>
        <div className="heading">AIF Investor Services</div>
        <div
          style={{
            marginLeft: "15%",
            marginTop: "14%",
            fontSize: 15,
            color: "#3FABE7",
          }}
        >
          Change Password
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "35vw",
            gap: "30px",
            fontFamily: "Poppins",
            margin: "auto",
            marginTop: "5%",
          }}
        >
          <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{
              username: userName,
              password: password,
            }}
            onFinish={onFinish}
            style={{
              width: "80%",
            }}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input
                value={userName}
                disabled={true}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
                autoComplete="off"
              />
            </Form.Item>
            <div>Old Password</div>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                autoComplete="off"
              />
            </Form.Item>
            <div>New Password</div>
            <Form.Item
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
                {
                  pattern: /^(?=.*[a-z])/,
                  message:
                    "Password must contain at least one lowercase letter",
                },
                {
                  pattern: /^(?=.*[A-Z])/,
                  message:
                    "Password must contain at least one uppercase letter",
                },
                {
                  pattern: /^(?=.*\d)/,
                  message: "Password must contain at least one number",
                },
                {
                  pattern: /^(?=.*[!@#$%^&*])/,
                  message:
                    "Password must contain at least one special character (!@#$%^&*)",
                },
                {
                  min: 8,
                  message: "Password must be at least 8 characters long",
                },
              ]}
            >
              <Input.Password
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Enter New Password"
                autoComplete="off"
              />
            </Form.Item>
            <div>Confirm Password</div>
            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                { required: true, message: "Please confirm your password!" },
                validateConfirmPassword,
              ]}
            >
              <Input.Password
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Re-Enter New Password"
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  flexDirection: "column",
                }}
              >
                <Button
                  type="primary"
                  // htmlType='submit'
                  className="login-form-button"
                  onClick={onPressChangePassword}
                >
                  Change password
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        {/* <div style={{ textAlign: "center" }}>Terms of Use</div> */}
        {/* <AppIcons /> */}
      </div>
    </div>
  );
};

export default ChangePassword;
